<template>
<v-card class="elevation-0" style="height:100%;" :style="embedded_mode?'background-color:#999':'background-color:#eee'" :class="site_config.show_app_name_with_logo=='true'?'k-framework-list-card-show-app-name':''">
	<v-card-title v-if="!embedded_mode" class="py-0">
		<div class="k-framework-list-agency-title k-banner-color k-agency-color k-agency-title-extra" v-html="site_config.agency_name" @click="open_agency_url"></div>
		<div class="k-toolbar-app-title k-toolbar-app-title--framework-list">{{site_config.app_name}}</div> <!-- Note that this becomes visible when the window size is very narrow -->
		<h3 class="k-framework-list-header-line k-custom-heading-color">
			{{site_config.framework_list_title}} 
			<v-btn icon small class="ml-1" style="margin-top:-4px" @click="U.show_help('framework_index')" color="light-blue"><v-icon>fas fa-info-circle</v-icon></v-btn>
		</h3>
		
		<v-spacer></v-spacer>

		<div class="mr-3" style="margin-top:-24px;" v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm"><b style="font-size:14px;color:#666">VIEW AS:</b>
			<v-btn-toggle dense active-class="k-toggle-btn-active-class" color="primary" class="k-toggle-btn" v-model="framework_list_view_mode" mandatory>
				<v-btn v-if="site_config.map_mode_enabled=='true'" small light value="map"> Map </v-btn>
				<v-btn small light value="tiles">Tiles</v-btn>
				<v-btn small light value="table">Table</v-btn>
			</v-btn-toggle>
		</div>

		<v-menu :transition="false" bottom left><template v-slot:activator="{on}"><v-btn v-on="on" color="primary" small class="ml-3" style="margin-top:-22px;" v-show="can_create_new&&!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm"><v-icon small class="mr-2">fas fa-plus</v-icon>New Framework</v-btn></template>
			<v-list dense>
				<v-list-item @click="create_new_framework"><v-list-item-icon><v-icon small>fas fa-file-circle-plus</v-icon></v-list-item-icon><v-list-item-title>Create new framework from scratch</v-list-item-title></v-list-item>
				<v-list-item @click="import_framework"><v-list-item-icon><v-icon small>fas fa-file-arrow-up</v-icon></v-list-item-icon><v-list-item-title>Import framework from CASE JSON</v-list-item-title></v-list-item>
				<v-list-item @click="import_framework_mirror"><v-list-item-icon><v-icon small>fas fa-clone</v-icon></v-list-item-icon><v-list-item-title>Mirror framework from another CASE server</v-list-item-title></v-list-item>

			</v-list>
		</v-menu>
	</v-card-title>
	<v-card-text v-if="!embedded_mode">
		<div class="k-framework-list-search-wrapper"><v-text-field v-model="search" dense outlined rounded clearable append-icon="fa fa-search" label="Search framework titles, categories, and identifiers" background-color="#fff" single-line hide-details @input="handle_search"></v-text-field></div>

		<div class="k-framework-list-wrapper elevation-3"><div class="k-framework-list-wrapper-inner">
			<div v-if="site_config.site_headline" class="k-framework-list-headline elevation-3">
				<div v-if="!framework_list_header_dismissed">
					<div v-html="site_config.site_headline"></div>
					<div class="d-flex mx-2 mt-3 align-end">
						<v-btn v-if="site_config.show_subject_coverage=='true'&&!$vuetify.breakpoint.xs" small outlined color="secondary" class="k-tight-btn k-nocaps-btn" @click="show_subject_table=true"><v-icon small class="mr-2">fas fa-table</v-icon>Subject Coverage Table</v-btn>
						<v-spacer/>
						<div v-for="(params, index) in site_config.site_headline_btns" :key="index" :class="index?'ml-2':''"><v-tooltip :disabled="!params.tooltip" bottom><template v-slot:activator="{on}"><v-btn v-on="on" small color="primary" @click="site_headline_btn_clicked(params)"><v-icon v-if="params.icon" small class="mr-2">{{params.icon}}</v-icon>{{params.text}}</v-btn></template><div style="max-width:400px;" v-html="params.tooltip"></div></v-tooltip></div>
					</div>
				</div>
				<div v-if="framework_list_header_dismissed" class="d-flex align-center">
					<v-spacer/>
					<div class="k-framework-list-welcome-msg" v-html="site_config.site_headline_welcome"></div>
					<v-btn small icon class="ml-3" color="light-blue" @click="framework_list_header_dismissed=false"><v-icon>fas fa-circle-info</v-icon></v-btn>
					<v-spacer/>
				</div>
			</div>

			<div v-if="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm&&(list_includes_sandbox||show_mirrors_checkbox||show_crosswalks_checkbox||show_no_limiters_checkbox)" class="d-flex mt-1 mr-4"><v-spacer/>
				<v-checkbox v-if="show_crosswalks_checkbox&&framework_list_view_mode=='table'" class="mt-0 pt-0 ml-6" color="#666" v-model="show_crosswalks" hide-details><template v-slot:label><span style="font-size:14px">Show Crosswalks</span></template></v-checkbox>
				<v-checkbox v-if="show_mirrors_checkbox" class="mt-0 pt-0 ml-6" color="#666" v-model="show_mirrors_only" hide-details><template v-slot:label><span style="font-size:14px">Show Mirrors only</span></template></v-checkbox>
				<v-checkbox v-if="list_includes_sandbox" class="mt-0 pt-0 ml-6" color="#666" v-model="framework_list_show_sandboxes" hide-details><template v-slot:label><span style="font-size:14px">Show Sandboxes</span></template></v-checkbox>
				<v-checkbox v-if="show_no_limiters_checkbox" class="mt-0 pt-0 ml-6" color="#666" v-model="framework_list_force_include_all_frameworks" hide-details><template v-slot:label><span style="font-size:14px">Don’t limit by category/subject</span></template></v-checkbox>
			</div>

			<div v-if="framework_list_view_mode=='tiles'">
				<FrameworkCategory v-for="(cr, i) in category_records" :key="cr.category" v-show="cr.showing" :search="search" :cr="cr" :case_tree_lsdoc_identifier="case_tree_lsdoc_identifier" @view_framework="view_framework" />
			</div>

			<div v-if="framework_list_view_mode=='map'">
				<FrameworkMap :category_records="category_records" @view_framework="view_framework" />
			</div>

			<div v-if="framework_list_view_mode=='table'" style="position:relative" class="pt-2">
				<div v-if="selected_frameworks.length>0" style="position:absolute; z-index:1; left:50px; width:200px; background-color:#fff">
					<v-menu :transition="false" bottom left><template v-slot:activator="{on}"><v-btn v-on="on" small color="#666" dark><v-icon small class="mr-2">fas fa-arrow-right</v-icon> With selected…</v-btn></template>
						<v-list min-width="250" dense>
							<v-list-item @click="copy_framework_data"><v-list-item-icon><v-icon small>fas fa-clipboard</v-icon></v-list-item-icon><v-list-item-title>Copy framework data to clipboard…</v-list-item-title></v-list-item>
							<v-list-item v-show="signed_in&&selected_frameworks.length==2" @click="open_selected_crosswalk"><v-list-item-icon><v-icon small>fas fa-shuffle</v-icon></v-list-item-icon><v-list-item-title>Create/edit crosswalk…</v-list-item-title></v-list-item>
							<!-- <v-list-item @click="export_frameworks"><v-list-item-icon><v-icon small>fas fa-file-download</v-icon></v-list-item-icon><v-list-item-title>Download framework(s)…</v-list-item-title></v-list-item>
							<v-list-item @click="rename_frameworks"><v-list-item-icon><v-icon small>fas fa-heading</v-icon></v-list-item-icon><v-list-item-title>Rename framework(s)…</v-list-item-title></v-list-item> -->
							<v-list-item v-if="can_bulk_edit" @click="change_framework_status"><v-list-item-icon><v-icon small>fas fa-flag</v-icon></v-list-item-icon><v-list-item-title>Update adoption status for frameworks…</v-list-item-title></v-list-item>
							<v-list-item v-if="can_bulk_edit" @click="delete_frameworks"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete frameworks…</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</div>

				<v-data-table dense class="k-framework-list-table" hide-default-footer show-select v-model="selected_frameworks" item-key="identifier"
					:headers="table_headers"
					:items="table_filtered_frameworks"
					:items-per-page="-1"
					:must-sort="true"
					:sort-by.sync="table_sort_by"
					:sort-desc.sync="table_sort_desc"
				>
					<template v-slot:item.title="{ item }">
						<div class="d-flex align-center">
							<v-btn fab x-small :class="framework_color(item)" :style="framework_color_style(item)" dark class="mr-1 my-1" @click="view_framework(item.identifier,null,$event)"><v-icon small>fas fa-map</v-icon></v-btn>
							<div class="ml-2">
								<a v-html="item.title" @click="view_framework(item.identifier,null,$event)"></a>
							</div>
							<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-if="item.is_sandbox" class="ml-3" style="white-space:nowrap;"><v-icon small class="mr-1" color="primary">fas fa-umbrella-beach</v-icon></div></template><div class="text-center">This is a “sandbox” copy of the original framework.</div></v-tooltip>
							<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-if="item.is_derivative&&user_can_edit[item.identifier]" class="ml-3" style="white-space:nowrap;"><v-icon small class="mr-1" color="deep-purple darken-2">fas fa-arrow-up-from-bracket</v-icon></div></template><div class="text-center">This is a “derivative” copy of another framework.</div></v-tooltip>
						</div>
					</template>
					<template v-slot:item.adoptionStatus="{ item }"><div class="k-case-tree-item-adoption-status" :class="adoption_status_css_class(item)"><nobr>{{item.adoption_status_display_value}}</nobr></div></template>
					<template v-slot:item.lastChangeDateTime="{ item }">
						<nobr>
							{{formatted_date(item.lastChangeDateTime)}}
							<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="item.is_mirror=='yes'&&user_can_edit[item.identifier]" class="ml-2" x-small fab outlined :color="item.mirror_ok?'cyan darken-3':'pink darken-3'" @click="mirror_to_show=item.identifier"><v-icon>fas {{item.mirror_ok?'fa-clone':'fa-triangle-exclamation'}}</v-icon></v-btn></template><div class="text-center">This framework is “mirrored”.<br>Click for info</div></v-tooltip>
						</nobr>
					</template>
					<template v-slot:item.identifier="{ item }"><nobr><span style="font-size:10px" v-html="item.identifier"></span><CopyBtn :size="'small'" color="#999" :val="item.identifier"/></nobr></template>
					<template v-slot:item.n_crosswalks="{ item }"><v-btn v-if="item.n_crosswalks>0" small outlined color="primary" @click.stop="show_crosswalks_for_identifier=item.identifier"><span style="font-size:18px">{{item.n_crosswalks}}</span><v-icon small class="ml-1">fas fa-shuffle</v-icon></v-btn></template>
				</v-data-table>
				<div class="my-3 d-flex align-center"><v-spacer/>
					<v-checkbox class="mt-0 pt-0" v-model="show_identifiers_in_framework_table" hide-details><template v-slot:label><span style="font-size:14px;white-space:nowrap;">Show framework identifiers (GUIDs) in table</span></template></v-checkbox>
					<!-- <v-spacer/> -->
					<!-- <div v-if="n_creator_publisher_mismatches>0" class="ml-3" style="font-size:14px; line-height:17px; max-width:540px;"><b>Note:</b> Frameworks whose status value is shown in <b class="k-case-tree-item-adoption-status-unofficial white--text px-1" style="border-radius:4px">BLUE</b> have been published in CASE format by an organization other than the issuing agency for the framework.</div> -->
					<v-spacer/>
				</div>
			</div>
		</div></div>

	</v-card-text>
	<CASEFrameworkViewer v-if="initialized && !case_tree_lsdoc_identifier_is_shortcut && case_tree_lsdoc_identifier"
			@hide_tree="hide_case_tree"
			@set_mode="set_mode"
			@set_starting_lsitem_identifier="set_starting_lsitem_identifier"
			:lsdoc_identifier="case_tree_lsdoc_identifier"
			:starting_lsitem_identifier="starting_lsitem_identifier"
			:starting_tree_key="starting_tree_key"
			:show_doc_info_on_open="false"
			:minimized_param="minimized_param"
		/>
	<CrosswalkEditor v-if="initialized && crosswalk_lsdoc_identifiers"
			:crosswalk_lsdoc_identifiers="crosswalk_lsdoc_identifiers"
		/>
	<DocumentEditor v-if="new_framework" :framework_record="new_framework"
			@dialog_cancel="cancel_new_framework" @view_framework="view_framework"
		/>
	<ImportMirror v-if="show_import_mirror_dialog"
		@view_framework="view_framework"
		@dialog_cancel="show_import_mirror_dialog=false"
	/>
	<MirrorInfo v-if="mirror_to_show" :lsdoc_identifier="mirror_to_show" @mirror_info_cancel="mirror_to_show=null" />
	<FrameworkSubjectTable v-if="show_subject_table" :category_records="category_records" @dialog_cancel="show_subject_table=false" />
	<TrackChangesLegend ref="track_changes_legend" v-show="tracking_changes" />
	<CrosswalkListForIdentifier v-if="show_crosswalks_for_identifier" :framework_identifier="show_crosswalks_for_identifier" @open_crosswalk="open_crosswalk" @dialog_cancel="show_crosswalks_for_identifier=null" />
</v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkCategory from './FrameworkCategory'
import FrameworkMap from './FrameworkMap'
import CASEFrameworkViewer from '../CASEFrameworkViewer/CASEFrameworkViewer'
import CrosswalkEditor from '../crosswalks/CrosswalkEditor'
import DocumentEditor from '../edit/DocumentEditor'
import ImportMirror from '../edit/ImportMirror'
import MirrorInfo from '../CASEFrameworkViewer/MirrorInfo'
import FrameworkSubjectTable from './FrameworkSubjectTable'
import CopyBtn from '../utilities/CopyBtn'
import TrackChangesLegend from '../CASEFrameworkViewer/TrackChangesLegend'
import CrosswalkListForIdentifier from '../crosswalks/CrosswalkListForIdentifier'
import goTo from 'vuetify/lib/services/goto'

export default {
	components: { FrameworkCategory, CASEFrameworkViewer, CrosswalkEditor, DocumentEditor, ImportMirror, MirrorInfo, FrameworkMap, FrameworkSubjectTable, CopyBtn, TrackChangesLegend, CrosswalkListForIdentifier },
	props: {
		case_tree_lsdoc_identifier: { type: String, required: false, default() { return '' }},
		starting_lsitem_identifier: { type: String, required: false, default() { return '' }},
		starting_tree_key: { type: String, required: false, default() { '' }},
		query: { type: Object, required: false, default() { return {} }},
		crosswalk_lsdoc_identifiers: { required: false, default() { return null }},
		// crosswalk_lsdoc_identifier_2: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		initialized: false,
		search: null,
		category_records: [],
		new_framework: null,
		selected_frameworks: [],
		show_import_mirror_dialog: false,
		mirror_to_show: null,
		show_subject_table: false,
		n_creator_publisher_mismatches: 0,
		show_crosswalks_for_identifier: null,
	}},
	computed: {
		...mapState(['framework_records', 'user_info', 'site_config', 'embedded_mode']),
		...mapGetters(['signed_in', 'crosswalk_framework_hash']),
		show_mirrors_checkbox() {
			// show the checkbox for limiting to mirrors only if we're showing the table view and the user is a system admin
			if (this.framework_list_view_mode == 'table' && vapp.is_granted('super')) {
				// and we have at least one mirror
				return this.framework_records.findIndex(x=>x.ss_framework_data.is_mirror=='yes') > -1
			}
			return false
		},
		show_crosswalks_checkbox() {
			// show the checkbox for showing crosswalks if we're showing the table view and the user is a system admin (currently; we'll expand this later)
			if (this.framework_list_view_mode == 'table' && vapp.is_granted('super')) {
				return true
				// further limit to pepper for now
				// if (!this.user_info.email.includes('pepper')) return false
				// and we have at least one crosswalk
				// return this.framework_records.findIndex(x=>x.ss_framework_data.is_mirror=='yes') > -1
			}
			return false
		},
		show_no_limiters_checkbox() {
			if (!vapp.is_granted('super')) return false
			if (!empty(this.site_config.included_categories)) return true
			if (!empty(this.site_config.included_subjects)) return true
			return false
		},
		framework_list_force_include_all_frameworks: {
			get() { return this.$store.state.lst.framework_list_force_include_all_frameworks },
			set(val) { this.$store.commit('lst_set', ['framework_list_force_include_all_frameworks', val]) }
		},
		framework_list_header_dismissed: {
			get() { return this.$store.state.lst.framework_list_header_dismissed },
			set(val) { this.$store.commit('lst_set', ['framework_list_header_dismissed', val]) }
		},
		table_sort_by: {
			get() { return this.$store.state.lst.framework_list_table_sort_by },
			set(val) { this.$store.commit('lst_set', ['framework_list_table_sort_by', val]) }
		},
		table_sort_desc: {
			get() { return this.$store.state.lst.framework_list_table_sort_desc },
			set(val) { this.$store.commit('lst_set', ['framework_list_table_sort_desc', val]) }
		},
		framework_list_show_sandboxes: {
			get() { return this.$store.state.lst.framework_list_show_sandboxes },
			set(val) { this.$store.commit('lst_set', ['framework_list_show_sandboxes', val]) }
		},
		show_mirrors_only: {
			get() { return this.show_mirrors_checkbox && this.$store.state.lst.framework_list_show_mirrors_only },
			set(val) { this.$store.commit('lst_set', ['framework_list_show_mirrors_only', val]) }
		},
		show_crosswalks: {
			get() { return this.show_crosswalks_checkbox && this.$store.state.lst.framework_list_show_crosswalks },
			set(val) { this.$store.commit('lst_set', ['framework_list_show_crosswalks', val]) }
		},
		can_bulk_edit() {
			// people with super-user access can bulk edit
			return vapp.is_granted('super')
		},
		can_create_new() {
			if (!this.signed_in) return false
			return vapp.is_granted('create_new_framework')
		},
		minimized_param() { return this.query.minimized !== undefined },
		framework_list_view_mode: {
			get() {
				// on small screens, always use tile view (?)
				if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) return 'tiles'
				if (empty(this.$store.state.lst.framework_list_view_mode)) {
					// if user hasn't explicitly set framework_list_view_mode, use map if map_mode_enabled; otherwise use tiles
					if (this.site_config.map_mode_enabled == 'true') return 'map'
					else return 'tiles'
				}
				return this.$store.state.lst.framework_list_view_mode
			},
			set(val) {
				this.$store.commit('lst_set', ['framework_list_view_mode', val])
			}
		},
		show_identifiers_in_framework_table: {
			get() { return this.$store.state.lst.show_identifiers_in_framework_table },
			set(val) { this.$store.commit('lst_set', ['show_identifiers_in_framework_table', val]) }
		},
		table_headers() {
			let arr = [
				{ text: 'Category', value: 'category', sortable: true, align: 'left' },
				{ text: 'Framework Title', value: 'title', sortable: true, align: 'left' },
				{ text: 'Subject', value: 'subject', sortable: true, align: 'left' },
				{ text: 'Status', value: 'adoptionStatus', sortable: true, align: 'center' },
				{ text: (this.show_mirrors_only ? 'Last Mirror Check': 'Last Changed'), value: 'lastChangeDateTime', sortable: true, align: 'left' },
			]
			if (this.show_crosswalks) {
				// arr.push({ text: ' X-To ', value: 'n_crosswalks_to', sortable: true, align: 'center' })
				// arr.push({ text: 'X-From', value: 'n_crosswalks_from', sortable: true, align: 'center' })
				arr.push({ text: 'Crosswalks', value: 'n_crosswalks', sortable: true, align: 'center' })
			}
			if (this.show_identifiers_in_framework_table) {
				arr.push({ text: 'Identifier', value: 'identifier', sortable: true, align: 'center' })
			}
			return arr
		},
		table_filtered_frameworks() {
			let n_creator_publisher_mismatches = 0	// count these as we go
			let arr = []
			for (let cr of this.category_records) {
				for (let fr of cr.framework_records) {
					if (cr.doc_showing[fr.lsdoc_identifier]) {
						let category = fr.ss_framework_data.category
						let category_data = U.parse_framework_category(category)

						// guess at subject; make non-breaking
						let subject = U.framework_subject_guess(fr.json.CFDocument)['label'].replace(/ /g, ' ')
						arr.push({
							category: category_data.title,
							identifier: fr.json.CFDocument.identifier,
							title: fr.json.CFDocument.title,
							subject: subject,
							creator: fr.json.CFDocument.creator,
							publisher: fr.json.CFDocument.publisher,
							adoptionStatus: fr.json.CFDocument.adoptionStatus,
							adoption_status_display_value: CFDocument.adoption_status_display_value(fr.json.CFDocument.adoptionStatus),
							is_mirror: fr.ss_framework_data.is_mirror,
							mirror_ok: fr.ss_framework_data.last_mirror_sync_status?.includes('OK'),
							
							// for lastChangeDateTime, use mirror_sync_date if show_mirrors_only is true
							lastChangeDateTime: this.show_mirrors_only ? date.format(U.convert_to_local_date(fr.ss_framework_data.last_mirror_sync_date), 'YYYY-MM-DD HH:mm') : fr.json.CFDocument.lastChangeDateTime,

							// number of crosswalks
							n_crosswalks: (this.crosswalk_framework_hash[fr.json.CFDocument.identifier]?.length ?? 0),

							is_sandbox: !empty(fr.ss_framework_data.sandboxOfIdentifier),
							is_derivative: !empty(fr.json.CFDocument.sourceFrameworkIdentifier) || !empty(fr.json.CFDocument.extensions?.sourceFrameworkIdentifier),
						})

						if (!empty(fr.json.CFDocument.publisher) && fr.json.CFDocument.creator != fr.json.CFDocument.publisher) ++n_creator_publisher_mismatches
					}
				}
			}
			this.n_creator_publisher_mismatches = n_creator_publisher_mismatches
			// console.log(`Total frameworks: ${arr.length} / creator-publisher mismatches: ${n_creator_publisher_mismatches} (${(n_creator_publisher_mismatches/arr.length*100).toFixed(1)}%)`)
			return arr
		},
		list_includes_sandbox() {
			for (let fr of this.$store.getters.filtered_framework_records) {
				if (!empty(fr.ss_framework_data.sandboxOfIdentifier)) return true
			}
		},
		// not currently used
		list_includes_crosswalk() {
			for (let fr of this.framework_records) {
				if (!vapp.is_granted('view_framework', fr.lsdoc_identifier)) continue
				if (fr.json.CFDocument.frameworkType == 'crosswalk') return true
			}
		},
		case_tree_lsdoc_identifier_is_shortcut() {
			if (!this.case_tree_lsdoc_identifier) return false
			if (this.case_tree_lsdoc_identifier.search(/^........-....-....-....-............/) == 0) return false
			return true
		},
		user_can_edit() {
			if (!this.signed_in) return {}
			let o = {}
			for (let fr of this.framework_records) {
				o[fr.lsdoc_identifier] = vapp.is_granted('edit_framework', fr.lsdoc_identifier)
			}
			return o
		},
		tracking_changes() {
			return (this.case_tree_lsdoc_identifier && vapp.case_tree_component && vapp.case_tree_component.tracking_changes)
		},
	},
	watch: {
		framework_records: {
			immediate: true, handler(val) {
				this.create_category_records()
				this.initialized = true
				this.redirect_shortcut()
			}
		},
		framework_list_force_include_all_frameworks() { this.create_category_records() },
		framework_list_show_sandboxes() { this.create_category_records() },
		show_mirrors_only() { this.create_category_records() },
		signed_in() { this.create_category_records() },
		case_tree_lsdoc_identifier() {
			// by calling handle_search whenever this changes, we re-filter based on the search result whenever the user comes back to this page after viewing a framework
			this.handle_search()

			this.redirect_shortcut()
		},
	},
	created() {
		// make sure frameworks_or_resources_toggle is set to 'frameworks'
		this.$store.commit('lst_set', ['frameworks_or_resources_toggle', 'frameworks'])

		vapp.framework_list_component = this
		// to check to see if a given framework_identifier is showing:
		// vapp.framework_list_component.case_tree_lsdoc_identifier == framework_identifier
	},
	mounted() {
	},
	methods: {
		open_agency_url() { window.open(this.site_config.agency_url) },

		// if the user used a "url shortcut" (e.g. "math") in the url, look up the corresponding framework and redirect to it
		redirect_shortcut() {
			// we also handle here the case where initialize_app sent back a start_string_identifiers value
			if (this.$store.state.start_string_identifiers) {
				this.view_framework(this.$store.state.start_string_identifiers.framework_identifier)
			}

			// it's a shortcut if the case_tree_lsdoc_identifier doesn't match the guid pattern
			if (this.case_tree_lsdoc_identifier_is_shortcut) {
				let fr = this.framework_records.find(x=>x.ss_framework_data.shortcuts.find(y=>y==this.case_tree_lsdoc_identifier))
				if (!fr) {
					this.$alert(sr('Unknown framework shortcut: “$1”', this.case_tree_lsdoc_identifier))
					vapp.go_to_route('')
				} else {
					this.view_framework(fr.lsdoc_identifier)
				}
			}
		},

		adoption_status_css_class(doc) { return CFDocument.adoption_status_css_class(doc) },

		framework_color(doc) {
			const framework_color = U.framework_color(doc.identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-dark'
			return ''
		},
		framework_color_style(doc) {
			return U.framework_color_object(doc.identifier)
		},
		formatted_date(s) {
			return U.local_last_change_date_time(s)
		},

		create_category_records() {
			// this fn is in case_utilities, because we also need it for FrameworkSwitcher and UserPrefs
			this.category_records = U.create_category_records(this.framework_list_show_sandboxes, this.show_mirrors_only)
		},

		handle_search() {
			// establish the debounce fn if necessary
			if (empty(this.handle_search_debounced)) {
				this.handle_search_debounce_worker = (s) => {
					let search_term_res = U.create_search_re(this.search)[0]

					for (let cr of this.category_records) {
						let cr_showing
						let category_match = false
						// when search gets set to empty, show all categorys
						if (empty(s)) {
							cr_showing = true

						// else if search is in category, show; otherwise hide by default, but we could turn it on if we find a framework in the category that should be shown
						} else {
							if (cr.category && U.strings_match_search_term_res(search_term_res, [cr.category])) {
							// if (cr.category.toLowerCase().indexOf(s) > -1) {
								cr_showing = true
								category_match = true	// we use this below to determine if the category was matched by the search terms
							} else {
								cr_showing = false
							}
						}

						// go through all framework_records
						for (let fr of cr.framework_records) {
							// if the search is 'mirror', just show all mirrors
							if (s == 'mirror') {	//  && this.framework_list_view_mode == 'table') {
								if (fr.ss_framework_data.is_mirror == 'yes') {
									cr.doc_showing[fr.lsdoc_identifier] = true
									cr_showing = true
								} else {
									cr.doc_showing[fr.lsdoc_identifier] = false
								}

							// if search is empty OR the search terms are in the creator, show all doc titles, but hide all doc_infos
							} else if (empty(s) || category_match) {
								cr.doc_showing[fr.lsdoc_identifier] = true
								
							} else {
								// else if title or identifier matches search, show (and make sure cr is showing)
								let search_title = fr.json.CFDocument.title.toLowerCase()
								if (!empty(fr.ss_framework_data.sandboxOfIdentifier)) search_title += ' sandbox'
								search_title += fr.lsdoc_identifier

								if (U.strings_match_search_term_res(search_term_res, [search_title])) {
								// if (search_title.indexOf(s) > -1 || fr.lsdoc_identifier.toLowerCase().indexOf(s) > -1) {
									cr.doc_showing[fr.lsdoc_identifier] = true
									// don't show doc_info?
									cr_showing = true
								} else {
									cr.doc_showing[fr.lsdoc_identifier] = false
								}
							}
						}
						cr.showing = cr_showing
					}
				}
				this.handle_search_debounced = U.debounce(function(s) {
					this.handle_search_debounce_worker(s)
				}, 200)
			}

			// if search is empty, handle it right away
			let s = $.trim(this.search).toLowerCase()
			if (empty(s)) {
				this.handle_search_debounce_worker(s)
			} else {
				// else call the debounce fn
				this.handle_search_debounced(s)
			}
		},

		import_framework() {
			this.$prompt({
				title: 'Import Framework',
				text: 'Choose a CASE JSON file from your computer as the imported framework source.<div class="mt-2">Note: if a framework with the uploaded file’s identifier (GUID) already exists on the server, it will be overwritten by the uploaded file.</div>',
				promptType: 'file',		// default is 'text'
				acceptText: 'Import',
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()
				reader.onload = e => {
					// NOTE: keep this in synch with re_import_framework from DocumentEditor.vue
					// currently we only support CASE JSON imports

					// parse json (it will be stringified by save_framework_data)
					let json
					try {
						json = JSON.parse(e.target.result)
					} catch(e) {
						console.log(e)
						this.$alert('The uploaded file was not valid JSON (an error occurred when attempting to parse the file).')
						return
					}

					// if no CFDocument, it's not valid json
					// TODO: do more validation...
					if (!json.CFDocument) {
						this.$alert('The uploaded JSON did not include a CFDocument property, so it is not a valid CASE framework file.')
						return
					}

					// see if this file already exists
					let index = this.framework_records.findIndex(x=>x.lsdoc_identifier == json.CFDocument.identifier)
					if (index > -1) {
						this.$confirm({
						    title: 'Overwrite Existing Framework',
						    text: sr('This framework (“$1”) already exists. If you re-import it, the framework’s CASE JSON will be replaced by the newly-imported JSON. (Application-specific data such as the framework’s color will be preserved.) Do you want to proceed?', json.CFDocument.title),
						    acceptText: 'Re-Import CASE JSON',
							dialogMaxWidth: 600
						}).then(y => {
							this.complete_import_framework(json)
						}).catch(n=>{console.log(n)}).finally(f=>{})
					} else {
						this.complete_import_framework(json)
					}
				}
				// trigger the FileReader to load the text of the file
				reader.readAsText(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		complete_import_framework(json) {
			// set lsdoc_identifier in the json, and set update_document_date to 'no' so the server *doesn't* change the lastChangeDateTime
			json.lsdoc_identifier = json.CFDocument.identifier
			json.update_document_date = 'no'
			json.reimport = 'yes'	// re-import the entire json file

			// then save using the same save_framework_data fn that we use for creating new frameworks and saving changes to frameworks
			this.$store.dispatch('save_framework_data', json).then(()=>{
				// when we come back, add or update the document in the framework_records list
				let cfd_json = new CFDocument(json.CFDocument).to_json()	// it's probably overkill to do these transformation, but it shouldn't hurt

				let index = this.framework_records.findIndex(x=>x.lsdoc_identifier == cfd_json.identifier)
				if (index == -1) {
					let framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json})
					this.$store.commit('set', [this.framework_records, 'PUSH', framework_record])
				} else {
					// if we're "re-importing" an existing framework, create a new framework_record object, copying in old_framework's ss_framework_data; then when we open it we'll reload the new json from the server
					// note that if this is the case we won't have updated the ss_framework_data record in the DB, so we should get these values back when we reload
					let framework_record = U.create_framework_record(cfd_json.identifier, {CFDocument:cfd_json}, $.extend(true, {}, this.framework_records[index].ss_framework_data))
					this.$store.commit('set', [this.framework_records, 'SPLICE', index, framework_record])
				}

				// call hide_case_tree first, in case this was called from an open framework
				this.hide_case_tree()

				// then open the framework
				this.$nextTick(x=>this.view_framework(json.lsdoc_identifier))
			})
		},

		create_new_framework() {
			// generate a new CFDocument with an identifier, and a sample uri
			let cfd = new CFDocument()
			cfd.generate_identifier()
			cfd.generate_uri(vapp.$store.state.framework_domain)

			// TODO: remember last-entered/accessed creator and match that here
			cfd.creator = this.user_info.first_name + ' ' + this.user_info.last_name

			// TODO: remember last-entered/accessed adoptionStatus and language
			cfd.adoptionStatus = 'Private Draft'
			cfd.language = 'en'

			// we'll know it's a new framework because ss_framework_data.framework_json_loaded will be false
			let framework_record = U.create_framework_record(cfd.identifier, {CFDocument:cfd.to_json()})

			// store framework_record in state.framework_records; we'll take it back out if the user cancels
			this.$store.commit('set', [this.framework_records, 'PUSH', framework_record])

			// set new_framework to open the editor
			this.new_framework = framework_record
		},

		cancel_new_framework() {
			// the DocumentEditor will take care of removing the new_framework from framework_records if necessary
			this.new_framework = null
		},

		import_framework_mirror() {
			this.show_import_mirror_dialog = true
			return
		},

		delete_frameworks() {
			// console.log(this.selected_frameworks)
			let framework_words = U.ps('the selected framework', this.selected_frameworks.length, 'the ' + this.selected_frameworks.length + ' selected frameworks')
			this.$confirm({
			    title: 'Delete Framework(s)',
			    text: sr('Are you sure you want to delete $1? The framework(s) will be moved to the archives, and will no longer appear in the framework list.', framework_words),
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				let processed_docs = 0
				for (let doc of this.selected_frameworks) {
					++processed_docs
					this.$store.dispatch('delete_lsdoc', doc.identifier).then(()=>{
						--processed_docs
						if (processed_docs == 0) {
							U.loading_stop()
							this.selected_frameworks = []
						}
					})
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		open_selected_crosswalk() {
			this.open_crosswalk([this.selected_frameworks[0].identifier, this.selected_frameworks[1].identifier])
		},

		open_crosswalk(identifiers) {
			// make sure crosswalk list is not showing
			this.show_crosswalks_for_identifier = null
			vapp.go_to_route(`crosswalk/${identifiers[0]}/${identifiers[1]}`)
		},

		copy_framework_data() {
			if (vapp.signed_in_only('copy framework data to the clipboard')) return

			let msg = 'The following data will be copied to your clipboard for each framework:<ul>'
			msg += '<li>The framework/document <b>identifier</b></li>'
			msg += '<li>The framework/document <b>title</b></li>'
			msg += '<li>The <b>adoption status</b> of the framework</li>'
			msg += '<li>The <b>last-changed date</b> of the framework</li>'
			msg += '<li>The <b>CASE REST API URL</b> for downloading the framework in CASE JSON format</li>'
			msg += '</ul><div class="my-2">Select a format for the CASE REST API URLs to be copied:</div>'
			msg += sr('<ul class="mb-2"><li><b>CASE 1.0</b> includes only fields that are part of the official CASE 1.0 specification.</li><li><b>CASE 1.1</b> includes fields added in CASE 1.1</li><li><b>Extended CASE</b> includes all CASE 1.1 fields, as well as additional fields that are editable/viewable in $1, but are not part of the official CASE spec.</li></ul>', this.$store.state.site_config.app_name)

			this.$prompt({
				title: 'Copy Framework Data to Clipboard',
				text: msg,
				promptType: 'select',
				selectOptions: [{value:'v1p0', text: 'CASE 1.0'}, {value:'v1p1', text: 'CASE 1.1'}, {value:'vext', text: 'Extended CASE'}],
				initialValue: 'v1p0',
				acceptText: 'Copy Data To Clipboard',
				dialogMaxWidth: 620,
			}).then(format => {
				let s = 'identifier	title	adoptionStatus	lastChangeDateTime	API-URL\n'
				for (let doc of this.selected_frameworks) {
					let fr = this.framework_records.find(x=>x.lsdoc_identifier == doc.identifier)
					s += fr.json.CFDocument.identifier + '\t'
					s += fr.json.CFDocument.title + '\t'
					s += fr.json.CFDocument.adoptionStatus + '\t'
					s += fr.json.CFDocument.lastChangeDateTime + '\t'
					// for a mirror, get the api url we mirror from?? no, for now at least (and especially for CASE Network), use this server's API
					if (false && fr.ss_framework_data.is_mirror == 'yes') {
						s += fr.ss_framework_data.mirror_source_rest_api_url + '\t'
					} else {
						// otherrwise construct the api url from this server
						s += U.case_api_url(fr.json.CFDocument.identifier, 'CFPackages', format)
					}
					s += '\n'
				}
				U.copy_to_clipboard(s)
				this.$alert('Data copied to clipboard. You can now paste this data into any spreadsheet or text editor.')
			})
		},

		export_frameworks() {
			this.$alert('This functionality is coming… In the meantime, you can view individual frameworks and download them from there.')
		},

		rename_frameworks() {
			this.$alert('This functionality is coming… In the meantime, you can view individual frameworks and rename them from there.')
		},

		change_framework_status() {
			this.$prompt({
				title: 'Update Adoption Status for Frameworks',
				text: 'Select the new adoption status for the selected frameworks:',
				promptType: 'select',
				selectOptions: CFDocument.adoption_statuses,
				acceptText: 'Update adoption statuses',
				dialogMaxWidth: 620,
			}).then(adoptionStatus => {
				for (let doc of this.selected_frameworks) {
					let data = {
						lsdoc_identifier: doc.identifier,
						// we only have to send in the properties that are changed
						CFDocument: { identifier: doc.identifier, adoptionStatus: adoptionStatus },
						// temporarily get an edit lock, save the change, and then remove the lock
						check_out_and_in: 'yes',
					}
					this.$store.dispatch('save_framework_data', data).then((result)=>{
						// update adoptionStatus and lastChangeDateTime from result
						data.CFDocument.lastChangeDateTime = result.document_lastChangeDateTime

						// update the framework_record
						let fr = this.framework_records.find(x=>x.lsdoc_identifier == data.lsdoc_identifier)
						this.$store.commit('set', [fr.json.CFDocument, 'adoptionStatus', data.CFDocument.adoptionStatus])
						this.$store.commit('set', [fr.json.CFDocument, 'lastChangeDateTime', result.document_lastChangeDateTime])

						// also update to the document's cftree, if we have one
						if (!empty(fr.cfo?.cftree)) {
							this.$store.commit('set', [fr.cfo.cftree, 'lastChangeDateTime', result.document_lastChangeDateTime])
							this.$store.commit('set', [fr.cfo.cftree, 'adoptionStatus', data.CFDocument.adoptionStatus])
							this.$store.commit('set', [fr.cfo.cftree.cfitem, 'lastChangeDateTime', result.document_lastChangeDateTime])
							this.$store.commit('set', [fr.cfo.cftree.cfitem, 'adoptionStatus', data.CFDocument.adoptionStatus])
						}
					})
				}
			})
		},

		get_query(mode, val) {
			let query = {}

			if (mode == 'minimized') {
				if (val) query.minimized = null
			} else {
				if (this.minimized_param) query.minimized = null
			}

			return query
		},

		get_query_string(mode, val) {
			let query = this.get_query(mode, val)
			let s = ''
			for (let key in query) {
				if (s) s += '&'
				s += key
			}
			if (s) s = '?' + s
			return s
		},

		hide_case_tree() {
			this.$router.push({name:'framework_list', query: this.get_query() })

			// re-call create_category_records, in case a framework's category was changed
			this.create_category_records()
		},

		view_framework(doc_identifier, item_identifier, $event) {
			// if doc_identifier is an array, extract the three params from the array
			if ($.isArray(doc_identifier)) {
				[doc_identifier, item_identifier, $event] = doc_identifier
			}

			// start url with the document identifier
			let url = '/' + doc_identifier

			// if an item_identifier is specified, add it so we open right to that item
			if (item_identifier) {
				url += '/' + item_identifier

			// else add the doc_identifier again, so that we'll open with the document's tile showing -- but not if we're on a small screen, or if start_string is specified
			} else if (!this.$vuetify.breakpoint.xs && empty(this.$store.state.start_string)) {
				url += '/' + doc_identifier
				// on a small screen, the document's tile will take up too much space, and CASEFrameworkViewer will take care of showing the start_string item
			}

			// add the query string
			url += this.get_query_string()

			// if user holds meta/ctrl key down, open in a new tab/window
			if (U.meta_or_alt_key($event)) {
				window.open(url)
			} else {
				this.$router.push({ path: url })
			}
		},

		set_mode(mode, val) {
			// when setting the mode, use replace so that we don't record a new history item
			this.$router.replace({ query: this.get_query(mode, val) })
		},

		set_starting_lsitem_identifier(item_identifier, tree_key) {
			let url

			// tree_key is optional
			if (!empty(tree_key)) url = sr('/$1/$2/$3$4', this.case_tree_lsdoc_identifier, item_identifier, tree_key, this.get_query_string())
			else url = sr('/$1/$2$3', this.case_tree_lsdoc_identifier, item_identifier, this.get_query_string())

			// $router.push records a new history item, so that the back btn goes back to the previous item; replace doesn't
			this.$router.push({ path: url })
			// this.$router.replace({ path: url })
		},

		site_headline_btn_clicked(params) {
			if (params.click_fn) this[params.click_fn]()
			else if (params.url) window.open(params.url, params.url.replace(/\W/g, ''))
		},
		
		dismiss_header() { this.framework_list_header_dismissed = true },

	}
}
</script>

<style lang="scss">
.k-framework-list-agency-title {
	position:absolute;
	z-index:5;
	left:0px;
	padding:0 18px;
	top:-2px;
	border-radius:0 0 8px 0;
	// background-color will be added by k-banner-color
	// color will be added by k-agency-color
	// css can be tweaked by k-agency-title-extra
	font-size:18px;
	line-height:30px;
	font-weight:bold;
	cursor:pointer;
}

.k-toolbar-app-title--framework-list {
	display:none;
	padding-left:0px!important;
	width:100%;
	text-align:center;
	position:static;
	padding-top:8px;
	color:#000;
}

.k-framework-list-header-line {
	padding-top:44px;
	padding-bottom:4px;
	padding-left:8px;
	font-size:26px;
}

.k-framework-list-search-wrapper {
	max-width:520px;
	margin:-24px 0px 12px auto;
}

.k-framework-list-wrapper {
	border:1px solid #ccc;
	border-radius:10px;
	background-color:#fff;
	padding:0 6px;
}

.k-framework-list-wrapper-inner {
	max-height:calc(100vh - 192px);
	overflow:auto;	// we need this to be overflow:auto to make the tile positioning work right in the case viewer
}

.k-framework-list-table {
	th {
		white-space:nowrap;
	}
	td {
		font-size:14px!important;
		line-height:18px;
	}
	a {
		color:#000!important;
	}
	a:hover {
		text-decoration:underline;
	}
}

.k-framework-list-headline {
	max-width:700px;
	margin:16px auto 20px auto;
	background-color:$v-amber-accent-1;
	color:#000;
	padding:12px;
	border-radius:12px;
	font-size:16px;
	line-height:22px;

	a {
		font-weight:bold;
		background-color:$v-amber-darken-4!important;
		color:#fff!important;
		text-decoration:none;
		padding:0 3px;
		border-radius:4px;
		white-space:nowrap;
	}
	a:hover { text-decoration:underline; }
}

.k-framework-list-welcome-msg {
	font-size:22px;
}
</style>
