<template><div class="k-editor-wrapper-outer"><div class="k-editor-wrapper k-editor-wrapper-wide k-case-batch-editor" :class="top_css" :style="top_style_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="cancel_edit"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex">
		<div><v-icon class="mr-2" small>fas fa-wand-magic-sparkles</v-icon> Batch Editor</div>
		<v-spacer/>
		<!-- <v-btn color="secondary" small class="ml-2" @click="cancel_edit">Done</v-btn> -->
	</div>
	<div class="k-case-item-editor-scrollable k-case-item-editor-scrollable-taller" style="font-size:14px">
		<div>Check off one or more items, then click a button below to alter properties or delete.</div>
		<div class="mt-2 text-center">
			<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" small color="primary"><v-icon small class="mr-2">fas fa-edit</v-icon>Alter Property…</v-btn></template>
				<v-list dense min-width="250">
					<v-list-item @click="choose_prop('fullStatement')"><v-list-item-title>Full Statement</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('humanCodingScheme')"><v-list-item-title>Human-Readable Code</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('abbreviatedStatement')"><v-list-item-title>Abbreviated Statement</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('notes')"><v-list-item-title>Notes</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('supplementalNotes')"><v-list-item-title>Supplemental Info</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('CFItemType')"><v-list-item-title>Item Type</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('educationLevel')"><v-list-item-title>Education Level</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('language')"><v-list-item-title>Language</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('statusStartDate')"><v-list-item-title>Implementation Start Date</v-list-item-title></v-list-item>
					<v-list-item @click="choose_prop('statusEndDate')"><v-list-item-title>Retirement Date</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>

			<v-btn small color="red" dark class="ml-2" @click="delete_items"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Item(s)…</v-btn>
		</div>
		<div v-if="prop_chosen">
			<div class="my-4 pt-2 pl-1" style="font-size:18px; border-top:1px solid #ccc">Alter Property <b>{{prop_chosen_label}}</b></div>

			<div class="k-case-ie-line">
				<div class="k-case-ie-line-label mr-2 text-right" style="width:80px">Search for:<br><span style="font-weight:normal">(optional)</span></div>

				<v-text-field v-show="text_prop_chosen&&!date_prop_chosen" background-color="#fff" outlined dense hide-details v-model="search_text" placeholder="" autocomplete="new-password" clearable></v-text-field>

				<div v-show="date_prop_chosen"><v-menu ref="searchDateMenu" v-model="searchDateMenu" :close-on-content-click="false" :return-value.sync="search_text" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-on="on" v-bind="attrs" background-color="#fff" v-model="search_text" label="" outlined dense hide-details clearable></v-text-field>
					</template>
					<v-date-picker v-model="search_text" no-title scrollable @input="$refs.searchDateMenu.save(search_text)">
						<v-btn v-show="search_text" small text color="red darken-2" @click="$refs.searchDateMenu.save('')"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Clear</v-btn>
						<v-spacer></v-spacer>
						<v-btn small text color="primary" @click="searchDateMenu=false"><v-icon small class="mr-1">fas fa-circle-xmark</v-icon>Close</v-btn>
					</v-date-picker>
				</v-menu></div>

				<div v-show="prop_chosen=='CFItemType'"><v-select background-color="#fff" v-model="search_CFItemType" :items="item_types_for_search" label="" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>

				<div v-show="prop_chosen=='language'"><v-select background-color="#fff" v-model="search_language" :items="languages" label="" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>

				<div class="d-flex" v-if="prop_chosen=='educationLevel'">
					<div style="flex:1 1 50%"><v-select style="width:100px" background-color="#fff" v-model="search_grade_low" :items="grades" label="Low" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
					<div class="ml-1" style="flex:1 1 50%"><v-select style="width:100px" background-color="#fff" v-model="search_grade_high" :items="grades" label="High" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
				</div>

			</div>
			<!-- <div v-if="text_prop_chosen&&search_text[0]=='/'" style="margin-left:92px; margin-top:-10px; margin-bottom:12px; font-size:12px;"><i>Remember that for regular expression searches, you must use the “i” modifier to do a case-insensitive search, and the “g” modifier to replace all instances of the search pattern.</i></div> -->

			<div class="k-case-ie-line">
				<div class="k-case-ie-line-label mr-2 text-right" style="width:80px">Replace with:</div>

				<div v-show="prop_chosen=='CFItemType'" :class="replace_CFItemType=='NEW'?'mr-2':''"><v-select background-color="#fff" v-model="replace_CFItemType" :items="item_types_for_replace" label="" outlined dense hide-details :menu-props="{top:false,dense:true}"></v-select></div>

				<div v-show="prop_chosen=='language'"><v-select background-color="#fff" v-model="replace_language" :items="languages" label="" outlined dense hide-details :menu-props="{top:false,dense:true}"></v-select></div>

				<div class="d-flex" v-if="prop_chosen=='educationLevel'">
					<div style="flex:1 1 50%"><v-select style="width:100px" background-color="#fff" v-model="replace_grade_low" :items="grades" label="Low" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
					<div class="ml-1" style="flex:1 1 50%"><v-select style="width:100px" background-color="#fff" v-model="replace_grade_high" :items="grades" label="High" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
				</div>

				<v-text-field v-show="!date_prop_chosen&&(text_prop_chosen||(prop_chosen=='CFItemType'&&replace_CFItemType=='NEW'))" background-color="#fff" outlined dense hide-details v-model="replace_text" placeholder="" autocomplete="new-password" clearable></v-text-field>

				<div v-show="date_prop_chosen"><v-menu ref="replaceDateMenu" v-model="replaceDateMenu" :close-on-content-click="false" :return-value.sync="replace_text" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-on="on" v-bind="attrs" background-color="#fff" v-model="replace_text" label="" outlined dense hide-details clearable></v-text-field>
					</template>
					<v-date-picker v-model="replace_text" no-title scrollable @input="$refs.replaceDateMenu.save(replace_text)">
						<v-btn v-show="replace_text" small text color="red darken-2" @click="$refs.replaceDateMenu.save('')"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Clear</v-btn>
						<v-spacer></v-spacer>
						<v-btn small text color="primary" @click="replaceDateMenu=false"><v-icon small class="mr-1">fas fa-circle-xmark</v-icon>Close</v-btn>
					</v-date-picker>
				</v-menu></div>
			</div>

			<div class="k-case-ie-line mt-4">
				<div class="k-case-ie-line-label mr-1"><nobr>Apply changes to:</nobr></div>
				<div>
					<v-radio-group v-model="apply_to" hide-details class="mt-0 ml-2">
						<v-radio background-color="#fff" class="mb-1" value="selected_and_descendents"><template v-slot:label><span><span>Selected items <b>+ descendents</b></span> <span style="font-size:12px">(children, grandchildren, etc.)</span></span></template></v-radio>
						<v-radio background-color="#fff" class="mb-1" value="selected_only"><template v-slot:label><span>Selected items <b>only</b></span></template></v-radio>
						<v-radio background-color="#fff" class="mb-1" value="descendents_only"><template v-slot:label><span>Descendents of selected items <b>only</b></span></template></v-radio>
					</v-radio-group>
				</div>
			</div>

			<div class="k-case-ie-line" v-show="show_apply_to_empty_only_option">
				<v-spacer/>
				<v-checkbox class="mt-0 pt-0" v-model="apply_to_empty_only" hide-details><template v-slot:label><span style="font-size:14px">Apply changes only to items where the property value is currently empty</span></template></v-checkbox>
				<v-spacer/>
			</div>

		</div>

	</div>

	<div class="k-case-item-editor-buttons" v-if="ready_to_simulate">
		<v-spacer></v-spacer>
		<v-btn small v-if="ready_to_simulate" color="primary" @click="simulate_batch_update_clicked">Simulate Batch Update…<v-icon small class="ml-1">fas fa-arrow-right</v-icon></span></v-btn>
	</div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import ItemImporter from './ItemImporter'

export default {
	// components: { ItemImporter },
	props: {
		framework_record: { type: Object, required: true },
		viewer: { required: false, default() { return ''} },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		prop_chosen: '',
		apply_to: 'selected_and_descendents',
		apply_to_empty_only: false,

		search_text: '',
		replace_text: '',
		regexp: '',
		search_CFItemType: '',
		replace_CFItemType: '',
		search_language: '',
		replace_language: '',
		search_educationLevel: [],
		replace_educationLevel: [],
		searchDateMenu: false,
		replaceDateMenu: false,

		cfitem_updates: [],
		cfitem_original_values: [],
		items_considered: 0,
		items_processed_hash: {},
	}},
	computed: {
		...mapState(['framework_records', 'languages', 'grades']),
		...mapGetters([]),
		cfo() { return this.framework_record.cfo },
		lsdoc_identifier() { return this.framework_record.lsdoc_identifier },
		checked_items() { return this.framework_record.checked_items },
		checked_item_count() {
			let ct = 0
			for (let tree_key in this.checked_items) {
				if (this.checked_items[tree_key] == true) ++ct
			}
			return ct
		},
		CFDocument() { return this.framework_record.json.CFDocument },
		framework_maximized() {
			if (empty(this.viewer)) return false
			return this.viewer.maximized
		},
		item_types() {
			let arr = []

			// start with types in the document (these will be in alphabetical order)
			for (let t of this.cfo.item_types) {
				arr.push({value:t, text:t})
			}

			// then add additional types in standard_item_types that aren't already in the framework (this will include '-')
			for (let t of this.$store.state.standard_item_types) {
				if (!this.cfo.item_types.find(x=>x==t.value)) arr.push({value:t.value, text:t.text})
			}

			return arr
		},
		item_types_for_search() {
			let arr = this.item_types.concat([])
			// add an option for explicitly searching for empty items
			let index = arr.findIndex(x=>x.value=='')
			arr[index] = {value: 'EMPTY', text: '-- ITEMS WITH NO TYPE SPECIFIED --'}
			return arr
		},
		item_types_for_replace() {
			let arr = this.item_types.concat([])
			// replace the empty (-) option with something more explicit
			let index = arr.findIndex(x=>x.value=='')
			arr[index] = {value: 'EMPTY', text: '-- CLEAR ITEM TYPE FIELD --'}
			// add a new option
			arr.splice(index+1, 0, {value: 'NEW', text: '-- ENTER NEW ITEM TYPE --'})

			return arr
		},
		// Note: this grade_low/grade_high computed algorithm is borrowed from ItemEditor.vue
		search_grade_low: {
			get() {
				if (this.search_educationLevel.length == 0) return ''
				let el = this.search_educationLevel[0]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new lower-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current upper-limit education level
				let el_high = this.search_educationLevel[this.search_educationLevel.length - 1]
				let el_high_index = this.grades.findIndex(g => { return (g.value == el_high || ( !isNaN(el_high*1) && (g.value*1 == el_high*1) )) })

				// if el_high_index is < new_el_index,
				if (el_high_index < new_el_index) {
					// if 9th grade was chosen, default to 12, because most HS courses are 9-12
					if (new_el == '09') el_high_index = this.grades.findIndex(g=>g.value=='12')
					// else just include new_el_index
					else el_high_index = new_el_index
				}

				// include all grades between new_el and el_high
				let arr = []
				for (let i = new_el_index; i <= el_high_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.search_educationLevel = arr
			}
		},
		search_grade_high: {
			get() {
				if (this.search_educationLevel.length == 0) return ''
				let el = this.search_educationLevel[this.search_educationLevel.length-1]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new upper-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current lower-limit education level
				let el_low = this.search_educationLevel[0]
				let el_low_index = this.grades.findIndex(g => { return (g.value == el_low || ( !isNaN(el_low*1) && (g.value*1 == el_low*1) )) })

				// if el_low_index is > new_el_index, just include new_el_index
				if (el_low_index > new_el_index) el_low_index = new_el_index

				// include all grades between new_el and el_high
				let arr = []
				for (let i = el_low_index; i <= new_el_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.search_educationLevel = arr
			}
		},
		replace_grade_low: {
			get() {
				if (this.replace_educationLevel.length == 0) return ''
				let el = this.replace_educationLevel[0]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new lower-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current upper-limit education level
				let el_high = this.replace_educationLevel[this.replace_educationLevel.length - 1]
				let el_high_index = this.grades.findIndex(g => { return (g.value == el_high || ( !isNaN(el_high*1) && (g.value*1 == el_high*1) )) })

				// if el_high_index is < new_el_index,
				if (el_high_index < new_el_index) {
					// if 9th grade was chosen, default to 12, because most HS courses are 9-12
					if (new_el == '09') el_high_index = this.grades.findIndex(g=>g.value=='12')
					// else just include new_el_index
					else el_high_index = new_el_index
				}

				// include all grades between new_el and el_high
				let arr = []
				for (let i = new_el_index; i <= el_high_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.replace_educationLevel = arr
			}
		},
		replace_grade_high: {
			get() {
				if (this.replace_educationLevel.length == 0) return ''
				let el = this.replace_educationLevel[this.replace_educationLevel.length-1]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new upper-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current lower-limit education level
				let el_low = this.replace_educationLevel[0]
				let el_low_index = this.grades.findIndex(g => { return (g.value == el_low || ( !isNaN(el_low*1) && (g.value*1 == el_low*1) )) })

				// if el_low_index is > new_el_index, just include new_el_index
				if (el_low_index > new_el_index) el_low_index = new_el_index

				// include all grades between new_el and el_high
				let arr = []
				for (let i = el_low_index; i <= new_el_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.replace_educationLevel = arr
			}
		},
		ready_to_simulate() {
			// if an "alter property" option isn't chosen, changes can't be pending
			if (empty(this.prop_chosen)) return false

			return true
		},
		ready_to_select() {
			// if an "alter property" option isn't chosen, the user can't select searched-for items
			if (empty(this.prop_chosen)) return false

			// if apply_to_empty_only is on, the user can select all items where the selected property is empty
			if (this.apply_to_empty_only) return true

			// the prop_chosen search param must also be filled in
			if (this.text_prop_chosen) return !empty(this.search_text)
			else return (!empty(this['search_' + this.prop_chosen]))
		},
		top_css() {
			const framework_color = U.framework_color(this.framework_record.cfo.cftree.cfitem.identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-editor'
			return ''
		},
		top_style_css() {
			return U.framework_color_object(this.framework_record.cfo.cftree.cfitem.identifier, 'editor')
		},
		prop_chosen_label() {
			return {
				'humanCodingScheme': 'Human-Readable Code',
				'fullStatement': 'Full Statement',
				'abbreviatedStatement': 'Abbreviated Statement',
				'CFItemType': 'Item Type',
				'educationLevel': 'Education Level',
				'language': 'Language',
				'notes': 'Notes',
				'supplementalNotes': 'Supplemental Info',
				'statusStartDate': 'Implementation Date',
				'statusEndDate': 'Retirement Date',
			}[this.prop_chosen]
		},
		text_prop_chosen() {
			return this.prop_chosen == 'humanCodingScheme' || this.prop_chosen == 'abbreviatedStatement' || this.prop_chosen == 'fullStatement' || this.prop_chosen == 'notes' || this.prop_chosen == 'supplementalNotes' || this.prop_chosen == 'statusStartDate' || this.prop_chosen == 'statusEndDate'
		},
		date_prop_chosen() {
			// note that the date fields are both text fields (for the purposes of how values are manipulated) and date fields (for the purpose of entering the values)
			return this.prop_chosen == 'statusStartDate' || this.prop_chosen == 'statusEndDate'
		},
		show_apply_to_empty_only_option() {
			if (this.text_prop_chosen) return empty($.trim(this.search_text))
			else if (this.prop_chosen == 'educationLevel') return (empty(this.search_educationLevel[0]) && empty(this.search_educationLevel[1]))
			else return empty(this['search_' + this.prop_chosen])
		},
	},
	watch: {
	},
	created() {
		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		this.viewer.current_editor = this

		// debug
		vapp.batch_editor = this
	},
	mounted() {
		if (this.checked_item_count > 0) {
			let t1, t2, t3
			if (this.checked_item_count == 1) {
				t1 = 'is one item'
				t2 = 'this item'
				t3 = 'Item'
			} else {
				t1 = sr('are $1 items', this.checked_item_count)
				t2 = 'these items'
				t3 = 'Items'
			}
			this.$confirm({
			    title: 'Items Previously Selected',
			    text: sr('There $1 selected from a previous batch edit. Would you like to keep $2 selected, or uncheck all previously-selected items?', t1, t2),
			    acceptText: 'Uncheck ' + t3,
			    cancelText: sr('Leave $1 Selected', t3),
				dialogMaxWidth: 700
			}).then(y => {
				vapp.case_tree_tree.uncheck_all_items()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		}
	},
	methods: {
		choose_prop(prop) {
			this.prop_chosen = prop
			console.log(this.checked_items)

			// if a single item is selected, set its values for search and replace (except fullStatement/abbreviatedStatement/notes/supplementalNotes)
			if (!['fullStatement','abbreviatedStatement','notes','supplementalNotes'].includes(prop)) {
				let only_checked_node
				for (let tree_key in this.checked_items) {
					if (this.checked_items[tree_key]) {
						if (only_checked_node) {
							only_checked_node = null
							break
						} else {
							only_checked_node = tree_key
						}
					}
				}

				if (only_checked_node) {
					let cfitem = this.framework_record.cfo.tree_nodes_hash[only_checked_node].cfitem

					if (prop == 'CFItemType') { 
						this.search_CFItemType = U.item_type_string(cfitem) 
						this.replace_CFItemType = U.item_type_string(cfitem)
					} else if (prop == 'language') { 
						this.search_language = U.item_type_string(cfitem) 
						this.replace_language = U.item_type_string(cfitem) 
					} else if (prop == 'educationLevel') { 
						if (cfitem.educationLevel && cfitem.educationLevel.length > 0) {
							this.search_grade_low = cfitem.educationLevel[0]
							this.replace_grade_low = cfitem.educationLevel[0]
							this.search_grade_high = cfitem.educationLevel[cfitem.educationLevel.length-1]
							this.replace_grade_high = cfitem.educationLevel[cfitem.educationLevel.length-1]
						}
					} else { 
						this.search_text = cfitem[prop] 
						this.replace_text = cfitem[prop] 
					}
				}
			}
		},

		cancel_edit() {
			// we may have delted the item that was active prior to batch-editing, which would have changed its key; if so clear the active flag
			if (empty(this.cfo.tree_nodes_hash[this.framework_record.active_node])) {
				// clear the active node and starting_lsitem_identifier
				this.viewer.make_node_active('', true)
			}
			this.$emit('dialog_cancel')
		},

		checkbox_clicked(identifier_clicked, tree_key_clicked, val, evt) {
			// for the batch updater we don't need to do anything when a checkbox is clicked, because we keep track of the checked items using framework_record.checked_items
			// in theory we could select/deselect sibling items if evt.metaKey is true
		},

		delete_items() {
			if (this.checked_item_count == 0) {
				this.$alert('You haven’t chosen any items to delete!')
				return
			}

			let confirm_text = sr('Are you sure you want to delete $1 (and any children/grandchildren $2 might have)?', U.ps('this item', this.checked_item_count, 'these ' + this.checked_item_count + ' items'), U.ps('it', this.checked_item_count, 'they'))
			this.$confirm({
				title: 'Delete Item(s)',
				text: confirm_text,
				acceptText: 'Delete',
				acceptColor: 'red',
				dialogMaxWidth: 500
			}).then(y => {
				let nodes_to_delete = []
				for (let tree_key in this.checked_items) {
					if (this.checked_items[tree_key] != true) continue

					let node = this.framework_record.cfo.tree_nodes_hash[tree_key]
					if (!empty(node)) nodes_to_delete.push(node)
				}

				this.$store.dispatch('delete_framework_items', {framework_record: this.framework_record, nodes_to_delete: nodes_to_delete}).then(()=>{
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		select_searched_items() {
			// clear all checked items, and collapse all items
			this.$store.commit('set', [this.framework_record, 'checked_items', {}])
			U.loading_start('', 'select_searched_items')
			this.$cancelDialogs()

			// if there are < 100 checked items, collapse the tree in anticipation of opening the item's parents
			if (this.cfitem_updates.length < 100) {
				this.viewer.collapse_all()
			}

			setTimeout(x=>{
				// check all nodes that match items found in the simulate_batch_update process
				let nodes_selected = 0
				for (let item of this.cfitem_updates) {
					// get the cfitem
					let cfo_cfitem = this.framework_record.cfo.cfitems[item.identifier]
					// make every node for this item checked, and open its parents
					for (let node of cfo_cfitem.tree_nodes) {
						this.$store.commit('set', [this.checked_items, node.tree_key+'', true])
						++nodes_selected

						// if there are < 100 checked items, open the item's parents (if more than that number of items, it can be time-consuming to open parents)
						if (this.cfitem_updates.length < 100) {
							this.viewer.make_item_parents_open(item.identifier)
						}
					}
				}

				U.loading_stop('select_searched_items')
			}, 1000)
		},

		process_search_text() {
			// trim search_text and replace_text
			this.search_text = $.trim(this.search_text)
			this.replace_text = $.trim(this.replace_text)

			// for text fields,
			if (this.text_prop_chosen) {
				// if search_text is empty, we'll just replace with replace_text; otherwise make a regular expression if needed
				if (!empty(this.search_text)) {
					// if search_text is bounded with //, do a regular expression search
					if (this.search_text.search(/^\/(.*)\/([a-z]*)$/) === 0) {
						let re = RegExp.$1
						let modifiers = RegExp.$2
						try {
							this.regexp = new RegExp(re, modifiers)
						} catch(e) {
							console.log(e)
							// return e as error message
							return e
						}

						// if we're doing a regexp, allow for some control characters in replace_text
						this.replace_text = this.replace_text.replace(/\\n/g, '\n')
						this.replace_text = this.replace_text.replace(/\\t/g, '\t')

					} else {
						this.regexp = ''
					}
				}
			}

			// if search_xxx is not empty, make sure apply_to_empty_only is off -- that is, if the user has entered a search field, they obviously don't want to apply only to empty items
			if (this.text_prop_chosen) {
				if (!empty($.trim(this.search_text))) this.apply_to_empty_only = false
			} else if (this.prop_chosen == 'educationLevel') {
				if (empty(this.search_educationLevel[0]) && empty(this.search_educationLevel[1])) this.apply_to_empty_only = false
			} else {
				if (!empty(this['search_' + this.prop_chosen])) this.apply_to_empty_only = false
			}

			// return empty string as error message
			return ''
		},

		simulate_batch_update_clicked() {
			let error_msg = this.process_search_text()

			// an apply_to option must be chosen -- but currently we always have one of these options selected, so no need to check this
			// if (empty(this.apply_to)) error_msg = 'You must specify whether to apply your changes to selected items only, or to selected items plus their descendents (children, grandchildren, etc.)'

			// for non-text property options, we currently only allow CFItemType to replace with nothing (meaning clear the field); and for that one the user must explicitly choose CLEAR ITEM TYPE FIELD
			if (this.prop_chosen == 'CFItemType' && empty(this.replace_CFItemType)) error_msg = 'You must choose the Item Type to use for your batch update. Choose “CLEAR ITEM TYPE FIELD” to clear the Item Type field for selected items.'
			if (this.prop_chosen == 'language' && empty(this.replace_language)) error_msg = 'You must specify the Language to use for your batch update.'
			if (this.prop_chosen == 'educationLevel' && (empty(this.replace_educationLevel[0]) && empty(this.replace_educationLevel[1]))) error_msg = 'You must specify the grade range to use for your batch update.'

			// for text props, we do a search and replace on the text, so it's allowable to have either empty

			if (this.checked_item_count == 0) error_msg = 'You haven’t chosen any items to update!'

			if (error_msg) {
				this.$alert(error_msg)
				return
			}

			// if we get to here, proceed to run the simulation. do it in this setTimeout/promise so we show the loading indicator while it runs
			U.loading_start()
			setTimeout(x=>this.simulate_batch_update().then(x=>{
				U.loading_stop()
				console.log(this.cfitem_original_values, this.cfitem_updates)
				this.show_simulation_report()
			}).catch(e=>{
				U.loading_stop()
				console.log(e)
				this.$alert('An error occurred when attempting to batch update.')
			}), 100)
		},

		simulate_batch_update() {
			return new Promise((resolve, reject)=>{
				this.cfitem_updates = []
				this.cfitem_original_values = []
				this.items_considered = 0
				this.items_processed_hash = {}

				// if the document checkbox is checked...
				let process_items = true
				if (this.checked_items[this.framework_record.cfo.cftree.tree_key]) {
					// then if this.apply_to == 'selected_and_descendents', just process all the top-level items, ignoring the rest of checked_items
					if (this.apply_to == 'selected_and_descendents') {
						this.process_items = false
						for (let node of this.framework_record.cfo.cftree.children) {
							this.simulate_batch_update_worker(node)
						}
						// if we're not doing descendents, it doesn't make sense to do the document at all, so just do the other checked items
					}
				}

				// if we're doing the checked items, then for each one...
				if (process_items) {
					for (let tree_key in this.checked_items) {
						if (this.checked_items[tree_key] != true) continue
						// skip the tree node in this array (we would have processed the tree above)
						if (tree_key == this.framework_record.cfo.cftree.tree_key) continue

						// process this item's node (and children if needed)
						let node = this.framework_record.cfo.tree_nodes_hash[tree_key]
						this.simulate_batch_update_worker(node)
					}
				}

				resolve()
			})
		},

		simulate_batch_update_worker(node, node_is_child) {
			// if this item has already been processed (it could have been a child of a previously-processed item), don't process it again
			if (this.items_processed_hash[node.cfitem.identifier]) return
			this.items_processed_hash[node.cfitem.identifier] = true

			// if apply_to is selected_and_descendents, do descendents first, because we might return at any point below if this item isn't going to be processed
			if (this.apply_to == 'selected_and_descendents' && node.children.length > 0) {
				for (let child of node.children) {
					this.simulate_batch_update_worker(child)
				}
			}

			// if we're *only* doing descendents,
			if (this.apply_to == 'descendents_only') {
				// then if this *isn't* a child -- i.e., if it's one of the directly-selected nodes...
				if (!node_is_child) {
					// then process this item's childen, sending in true so we know it *is* a child
					for (let child of node.children) {
						this.simulate_batch_update_worker(child, true)
					}

					// then return, because we *don't* process the directly-selected nodes
					return
				}
			}

			// get the cfitem json (it's fine to use the copy of the json from cfo)
			let cfitem = node.cfitem

			++this.items_considered

			// if apply_to_empty_only is true, skip this item if the prop_chosen isn't empty
			if (this.apply_to_empty_only) {
				if (this.prop_chosen == 'educationLevel') {
					if (!empty(cfitem[this.prop_chosen]) && cfitem.educationLevel.length > 0) return
				} else {
					if (!empty(cfitem[this.prop_chosen])) return
				}
			}

			// remember the original value
			let original_value = (this.prop_chosen != 'supplementalNotes') ? cfitem[this.prop_chosen] : (cfitem.extensions?.supplementalNotes ?? '')
			if (this.prop_chosen == 'educationLevel' && original_value) original_value = original_value.join(', ')
			if (this.prop_chosen == 'CFItemType' && empty(original_value) && !empty(cfitem.CFItemTypeURI)) {
				// for item type, if we have a CFItemTypeURI, convert it to a string
				original_value = cfitem.CFItemTypeURI.title
			}

			// by default, the updated_value will be the same as the original_value
			let updated_value = original_value

			// for non-text fields...
			if (!this.text_prop_chosen) {
				// get search and replace value, either of which may be empty
				let search = this['search_' + this.prop_chosen]
				let replace = this['replace_' + this.prop_chosen]
				// for item type NEW, get from replace_text
				if (this.prop_chosen == 'CFItemType' && replace == 'NEW') replace = this.replace_text

				// if search isn't empty...
				if ((this.prop_chosen == 'educationLevel' && !(empty(search[0]) && empty(search[1]))) || (this.prop_chosen != 'educationLevel' && !empty(search))) {
					// we only replace if the value has the search value

					if (this.apply_to_empty_only) {
						// if we're explicitly searching for empty values, return if the original_value *isn't* empty
						if (!empty(original_value)) {
							return
						}

						// note that if search is '', we will match all values, whether empty or not

					} else if (this.prop_chosen == 'educationLevel') {
						// for educationLevel we have to compare arrays; if they don't match, return (i.e. don't process this item)
						if (original_value != search.join(', ')) {
							return
						}

					} else if (this.prop_chosen == 'CFItemType' && search == 'EMPTY') {
						// for CFItemType EMPTY, match if it's empty
						// so this means we *don't* match if original_value is *not* empty
						if (original_value) {
							return
						}

					} else {
						// for language or other CFItemTypes, we just have to do a string compare -- if it doesn't match search, return
						if (original_value != search) {
							return
						}
					}
				}

				// if we get to here, replace with replace -- unless replace is 'EMPTY', in which case replace with an empty value
				if (replace == 'EMPTY') updated_value = ''
				else updated_value = replace

			} else {
				// else for text fields...
				// if search_text is empty, we just replace with replace_text
				if (empty(this.search_text)) {
					updated_value = this.replace_text

				} else {
					// /-\d+$/
					// if we're supposed to search/replace, the value must not be empty; if it is, return
					if (empty(original_value)) return

					// else search/replace, using regexp or raw text
					if (empty(this.regexp)) {
						// non-regexp search/replace: https://stackoverflow.com/a/6724957
						updated_value = original_value.split(this.search_text).join(this.replace_text)
					} else {
						updated_value = original_value.replace(this.regexp, this.replace_text)
					}
				}

				// you can't have an empty fullStatement, so...
				if (this.prop_chosen == 'fullStatement' && empty(updated_value)) {
					// use a default value that should a) be obvious to the user that it's a mistake, and b) be easy to search for later if you accidentally change to this value
					updated_value = 'XXXX'
				}
			}

			// if nothing changed in the parent, return (i.e. don't process this item)
			if (original_value == updated_value || (empty(original_value) && empty(updated_value))) {
				return
			}

			// we only send into save_framework_data the properties that need to be updated, along with the identifier and lastChangeDateTime *NOW*
			let cfitem_update = {
				identifier: cfitem.identifier,
				lastChangeDateTime: '*NOW*'
			}

			// for supplementalNotes, edit extensions
			if (this.prop_chosen == 'supplementalNotes') {
				let o = cfitem.extensions ? object_copy(cfitem.extensions) : {}
				if (empty(updated_value)) delete o.supplementalNotes
				else o.supplementalNotes = updated_value
				if (!U.object_has_keys(o)) cfitem_update.extensions = '*CLEAR*'
				else cfitem_update.extensions = o
				
			// else if original_value is non-empty and updated_value is empty, set value to '*CLEAR*' to tell save_framework_data to delete the property
			} else if (!empty(original_value) && empty(updated_value)) {
				cfitem_update[this.prop_chosen] = '*CLEAR*'
			} else {
				// else set to updated value
				cfitem_update[this.prop_chosen] = updated_value
			}

			// if we're updating the CFItemType, we're setting CFItemType and making sure CFItemTypeURI is clear
			if (this.prop_chosen == 'CFItemType') {
				cfitem_update.CFItemTypeURI = '*CLEAR*'
			}

			// if we get to here we're making a change, so record the original value and the cfitem_update
			this.cfitem_original_values.push(original_value)
			this.cfitem_updates.push(cfitem_update)
		},

		show_simulation_report() {
			if (this.cfitem_updates.length == 0) {
				this.$alert(sr('None of the items you selected $1 would be updated using the criteria you set.', ((this.apply_to == 'selected_and_descendents') ? '(or their descendents)' : '')))
				return
			}

			let show_old_value = (this.prop_chosen != 'humanCodingScheme' && this.prop_chosen != 'fullStatement')

			// start table; add header, including the old value if we're going to show it
			let table = '<table class="k-batch-alter-report-table">'
			table += '<tr>'
			table += '<th>Item</th>'
			if (show_old_value) {
				table += '<th>Previous Value</th>'
			}
			table += '<th>New Value</th>'
			table += '</tr>'

			for (let i = 0; i < this.cfitem_updates.length; ++i) {
				let cfitem_update = this.cfitem_updates[i]
				let cfitem = this.cfo.cfitems[cfitem_update.identifier]
				let original_value = this.cfitem_original_values[i]

				// item hcs / fullStatement (abbreviated, unless the update was to the fullStatement)
				let item_text = ''

				if (this.prop_chosen == 'humanCodingScheme') {
					if (original_value) item_text += sr('<b>$1</b> ', original_value)
				} else {
					if (cfitem.humanCodingScheme) item_text += sr('<b>$1</b> ', cfitem.humanCodingScheme)
				}

				if (this.prop_chosen == 'fullStatement') {
					item_text += original_value
				} else {
					if (cfitem.fullStatement.length <= 50) item_text += cfitem.fullStatement
					else item_text += cfitem.fullStatement.substr(0, 50) + '…'
				}

				table += sr('<tr><td>$1</td>', item_text)

				// old value, unless the update was to the hcs or fullStatement
				if (show_old_value) {
					table += sr('<td>$1</td>', (original_value ? original_value : '–'))
				}

				// new value
				let new_value
				if (this.prop_chosen == 'supplementalNotes') {
					if (this.cfitem_updates[i]['extensions'] == '*CLEAR') new_value = '– (remove property)'
					else new_value = this.cfitem_updates[i]['extensions']['supplementalNotes']

				} else {
					new_value = this.cfitem_updates[i][this.prop_chosen]
					if (new_value === '*CLEAR*') new_value = '– (remove property)'
					else if (this.prop_chosen == 'educationLevel') new_value = new_value.join(', ')
				}
				table += sr('<td>$1</td>', new_value)
				table += '</tr>'
			}
			table += '</table>'

			let html = sr('<div class="mb-2" style="font-size:18px">Altering property: <b>$1</b></div>', this.prop_chosen_label)

			if (true) {
				html += sr('<ul class="mb-2"><li>Items to alter: <b>$1</b> (of $2 checked $3$4) <a class="ml-2" href="javascript:window.vapp.batch_editor.select_searched_items()"><b>SELECT THESE ITEMS</b></a></li></ul>', this.cfitem_updates.length, this.items_considered, U.ps('item', this.items_considered), ((this.apply_to == 'selected_and_descendents') ? ', including descendents' : ''))
			}

			// warn about certain things...
			let warning = ''
			if (this.text_prop_chosen && !this.search_text && this.items_considered > 20) {
				warning = sr('Are you sure you want to replace the $1 in all $2 items with the same text value?', this.prop_chosen_label, this.items_considered)
			}
			if (warning) {
				html += sr('<div class="mb-2 red--text"><b>$1</b></div>', warning)
			}

			html += table

			this.$confirm({
			    title: 'Batch Alter Items',
			    text: html,
			    acceptText: 'Make these Batch Updates!',
				dialogMaxWidth: 1000
			}).then(y => {
				this.run_batch_update()
			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		run_batch_update() {
			let data = {
				lsdoc_identifier: this.lsdoc_identifier,
				CFItems: this.cfitem_updates,
			}

			if (this.prop_chosen == 'CFItemType') {
				data.update_item_types = true	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes
			}

			console.log(data)

			U.loading_start()
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// for each updated item...
				for (let cfitem of this.cfitem_updates) {
					// create or update the CFItem in the framework json, as well as the cfo cfitem
					let json = this.framework_record.json.CFItems.find(x=>x.identifier == cfitem.identifier)
					let cfo_cfitem = this.framework_record.cfo.cfitems[cfitem.identifier]

					// update timestamp with the value sent back from the server
					this.$store.commit('set', [json, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
					this.$store.commit('set', [cfo_cfitem, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])

					// update the changed property
					if (this.prop_chosen != 'supplementalNotes') {
						let update_value = cfitem[this.prop_chosen]
						if (update_value === '*CLEAR*') update_value = '*DELETE_FROM_STORE*'	// deal with removed properties
						this.$store.commit('set', [json, this.prop_chosen, update_value])
						if (cfitem.CFItemTypeURI) this.$store.commit('set', [json, 'CFItemTypeURI', cfitem.CFItemTypeURI])
						this.$store.commit('set', [cfo_cfitem, this.prop_chosen, update_value])
					} else {
						let update_value = cfitem.extensions
						if (update_value === '*CLEAR*') update_value = '*DELETE_FROM_STORE*'	// deal with removed properties
						this.$store.commit('set', [json, 'extensions', update_value])
						this.$store.commit('set', [cfo_cfitem, 'extensions', update_value])
					}
				}
				U.loading_stop()
				this.$alert('Batch update complete.')
			})
		},
	}
}
</script>

<style lang="scss">
.k-case-batch-editor {
}

.k-batch-alter-report-table {
	margin-top:8px;
	border-collapse: collapse;
	th, td {
		padding:1px 6px;
		border:1px solid #ccc;
		font-size:12px;
	}
}
</style>
