<template>
	<div class="k-editor-wrapper-outer k-import-confirm-wrapper"><div class="k-editor-wrapper" :class="top_css" :style="top_style_css">
		<div class="ma-2">
			<v-btn small color="primary" @click="confirm_import">Save Imported Items</v-btn>
			<v-btn small class="ml-2" color="secondary" @click="cancel_import">Cancel Import</v-btn>
		</div>
	</div></div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		viewer: { required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState([]),
		framework_record() { return this.viewer.framework_record },
		CFDocument() { return this.framework_record.json.CFDocument },
		top_css() {
			const framework_color = U.framework_color(this.CFDocument.identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-editor'
			return ''
		},
		top_style_css() {
			return U.framework_color_object(this.CFDocument.identifier, 'editor')
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		confirm_import() {
			this.viewer.save_imported_items(this.viewer.import_data)
			this.$nextTick(x=>this.$emit('dialog_cancel'))
		},
		cancel_import() {
			this.viewer.cancel_item_import_review()
			this.$nextTick(x=>this.$emit('dialog_cancel'))
			// window.location.reload()
		},
	}
}
</script>

<style lang="scss">
.k-import-confirm-wrapper {
	position:fixed;
	top: auto;
	right:auto;
	bottom: 5px;
	left:5px;
	height:auto;

	.k-editor-wrapper {
		position:static;
		width:auto;
		font-size:14px;
	}
}

</style>
