<template>
	<div ref="color_picker">
		<div :style=color_picker_style>
			<v-color-picker class="k-color-picker" @update:color="update_color" v-model="picker_color" width="200" mode="hexa" right hide-mode-switch></v-color-picker>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		color: { type: String, required: true },
	},
	data() { return {
		picker_color: '#FFFFFF',
		default_colors: {
			'0': '#24818A',
			'1': '#AD1457',
			'2': '#4527A0',
			'3': '#283593',
			'4': '#1565C0',
			'5': '#00838F',
			'6': '#827717',
			'7': '#4E342E',
			'8': '#6A1B9A',
			'9': '#0277BD',
			'10': '#00695C',
			'11': '#2E7D32',
			'12': '#C62828',
			'13': '#E65100',
			'14': '#424242'
		}
	}},
	mounted() {
		if (!isNaN(this.color)) {
			// the color was not a custom color
			if (this.color in this.default_colors) {
				this.update_color({hex: this.default_colors[this.color]})	
				this.picker_color = this.default_colors[this.color]
			} else {
				this.update_color({hex: '#FFFFFF'})
				console.warn('this should not happen')
			}
			return
		}
		this.picker_color = this.color
	},
	computed: {
		color_picker_style() {
			return {
				// position: 'absolute',
				zIndex: 9999,
				'margin-left': '-160px'
			};
		},
	},
	methods: {
		update_color(new_color) {
			this.$emit('update_color', new_color.hex)
		},
	}
}
</script>

<style lang="scss">
.k-color-picker {
	margin-top: 50px;
	height: 250px;
	margin-left: '-160px';
	// box-shadow: none !important;
	box-shadow: 0 0 0 1px #ccc !important;
	border-radius: 8px;
	.v-color-picker__input span { display:none; }
	.v-color-picker__edit {margin-top: 12px}
}
// .color-picker-wrapper {
//   position: relative; /* Ensure the color picker is positioned relative to this wrapper */
// }
</style>
