<template>
    <v-dialog :value="value" @input="$emit('input')" scrollable persistent>
        
        <v-card v-bind="framework_color_bind" id="k-rubric-editor-dialog-card" style="display: flex; flex-direction: column; gap: 0px">
            <v-card-title class="k-rubric-editor-dialog-title pb-0">
                <div class="k-editor-title px-0 pt-0 d-flex">
                    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                        <div>
                            <v-icon class="mr-1">fas fa-edit</v-icon>
                            {{ titleText }}
                        </div>
                        <v-card-actions style="display: flex; flex-direction: column;">
                            <div style="flex-grow: 1"></div>
                            <div style="display:flex; justify-content: end; gap:16px; width: 100%;">
								<v-btn small color="red darken-3" dark @click="delete_rubric"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete Rubric</v-btn>
                                <template v-if="isStateChanged">
                                    <v-btn small color="secondary" @click="close_dialog">Cancel</v-btn>
                                    <v-btn small @click="save_rubric" v-bind="framework_color_bind_button" :disabled="!title">
                                        <span><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</span>
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <v-btn small @click="close_dialog" v-bind="framework_color_bind_button">Done Editing</v-btn>
                                </template>
                            </div>
                        </v-card-actions>
                    </div>
                    <v-spacer/>
                </div>
                
            </v-card-title>
            <v-card-text  class="k-rubric-editor-dialog-content pt-4">
                <div style="display: flex; gap: 16px">
                    <div style="flex:1;">
                        <div class="k-case-ie-line-label k-rubric-editor-required">Rubric Title:</div>
                        <v-text-field
                            background-color="#fff"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[rules.required]"
                            v-model="title"
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Rubric Description:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            rows="1"
                            v-model="description"
                            auto-grow
                            clearable
                        />
                    </div>
                    <div style="flex:1">
                        <div class="k-case-ie-line-label">Rubric Notes:</div>
                        <v-textarea
                            background-color="#fff"
                            outlined
                            dense
                            hide-details
                            rows="1"
                            v-model="notes"
                            auto-grow
                            clearable
                        />
                    </div>
                </div>
                <v-spacer />
                <CFRubricEditorForm v-model="criteria" :rubric_identifier="rubric_identifier" :framework_record="framework_record"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import CFRubricEditorForm from './CFRubricEditorForm.vue'

const DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_COUNT = 4
const DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_QUALITIES = ['Minimally Proficient', 'Partially Proficient', 'Proficient', 'Highly Proficient']

export default {
    name: "CFRubricEditorModal",
    components: {CFRubricEditorForm},
    props: {
        /** Controls the open state of the modal */
        value: {
            type: Boolean,
            default: false
        },
        cfitem: {
			type: Object,
            required: true
        },
        framework_record: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'value',
        event: 'update:value'
    },
    data() {return {
		rubric_identifier: undefined,
		rubric_association_identifier: undefined,
		is_new_rubric: true,
		title: "",
		description: "",
		notes: "",
		criteria: [],
		initialState: {
			title: "",
			description: "",
			notes: "",
			criteria: [],
		},
		rules: {
			required: value => !!value || 'Field is required',
		},
	}},
    computed: {
		item_identifier() { return this.cfitem.identifier },
        framework_color_bind: function() {
            const lsdoc_identifier = this.framework_record.lsdoc_identifier
            const frameworkColor = U.framework_color(lsdoc_identifier)

            if (!isNaN(frameworkColor)) {
                const editorClass = `k-framework-color-${frameworkColor}-editor`
                
                return {
                    class: [editorClass].join(" ")
                }
            } else {
                const frameworkColorObjectEditor = U.framework_color_object(lsdoc_identifier, 'editor')
                return {
                    style: frameworkColorObjectEditor
                }
            }
    
		},
        framework_color_bind_button: function() {
            const lsdoc_identifier = this.framework_record.lsdoc_identifier

            const frameworkColor = U.framework_color(lsdoc_identifier)

            if (!isNaN(frameworkColor)) {
                const backgroundClass = `k-framework-color-${frameworkColor}-dark`
                
                return {
                    class: backgroundClass,
                    style: {'color': 'white'}
                }
            } else {
                const frameworkColorObject = U.framework_color_object(lsdoc_identifier)
                return {
                    style: frameworkColorObject
                }
                    
            }
		},
        titleText: function() {
            return this.is_new_rubric
                ? 'Create Rubric (BETA)'
                : 'Edit Rubric (BETA)'
        },
        isStateChanged() {
            return this.title !== this.initialState.title 
                || this.description !== this.initialState.description
                || this.notes !== this.initialState.notes
                || JSON.stringify(this.criteria) !== JSON.stringify(this.initialState.criteria)
        }
    },
    mounted() {
		vapp.rubric_editor_modal_component = this

		// if we already have a rubric for the initiating CFItem, establish editable properties from it
		if (this.framework_record.cfo.rubric_associations_hash[this.item_identifier]) {
			// in theory we could have multiple rubrics tied to a single CFItem, but for now we assume we're editing the first one
			let rubric_assoc = this.framework_record.cfo.rubric_associations_hash[this.item_identifier][0]

			this.is_new_rubric = false
            this.rubric_identifier = rubric_assoc.destinationNodeURI.identifier
			this.rubric_association_identifier = rubric_assoc.identifier

            let rubric = this.framework_record.json.CFRubrics.find(x=>x.identifier === this.rubric_identifier)
            this.title = rubric.title;
            this.description = rubric.description
            this.notes = rubric.notes
			// note that we use an object_copy here
            this.criteria = object_copy(rubric.CFRubricCriteria)

		// else establish default properties for the new rubric
        } else {
			// create a new rubric_identifier here; we will set rubric_association_identifier when we save
            this.rubric_identifier = U.new_uuid()
            this.title = 'New Rubric';
            this.description = ''
            this.notes = ''

			// establish a default criterion and levels
			let rc = new CFRubricCriterion()
			rc.position = 1
			rc.rubricId = this.rubric_identifier
			rc.weight = 1
			rc.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri
			
			for (let i = 0; i < DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_COUNT; i++) {
				let rcl = new CFRubricCriterionLevel()
				// use default qualities defined above; this could be made configurable from instance to instance
				rcl.quality = DEFAULT_NEW_RUBRIC_CRITERION_LEVEL_QUALITIES[i]
				rcl.score = i + 1
				rcl.position = i + 1
				rcl.rubricCriterionId = rc.identifier
				rcl.complete_data(this.framework_record.json.CFDocument)	// this establishes the identifier and uri
                rc.CFRubricCriterionLevels.push(rcl)
            }

			// use the json version for consistency with what we do when this is an existing rubric
			this.criteria = [rc.to_json()]
        }
        this.set_initial_state()
    },
    methods: {
		// TODO: I think this could be a computed...
        set_initial_state() {
            this.initialState = {
                title: this.title,
                description: this.description,
                notes: this.notes,
                criteria: JSON.parse(JSON.stringify(this.criteria))
            }
        },

        save_rubric() {
			// create rubric object
            const rubric = new CFRubric({
                identifier: this.rubric_identifier,
                title: this.title,
                description: this.description,
                notes: this.notes,
                CFRubricCriteria: this.criteria
            })
			// complete the rubric, filling in uri and lastChangeDateTime -- lastChangeDateTime will be set to *NOW*, whether this is a new rubric or an existing rubric we're updating, which is what we want
			rubric.complete_data(this.framework_record.json.CFDocument)

			// start constructing the payload for save_framework_data
            let payload = { lsdoc_identifier: this.framework_record.lsdoc_identifier }

			let association = null
			if (this.is_new_rubric) {
				payload.CFRubrics = [rubric.to_json()]	// for a new rubric, we use to_json, not to_json_for_update

				// create association linking rubric to starting item, if it's a new rubric; otherwise we don't need to send the association in to save_framework_data
				association = new CFAssociation({
					originNodeURI: {
						title: U.generate_cfassociation_node_uri_title(this.cfitem),
						identifier: this.cfitem.identifier,
						uri: this.cfitem.uri
					},
					associationType: "ext:hasRubric",
					destinationNodeURI: {
						title: this.title,
						identifier: rubric.identifier,
						uri: rubric.uri
					}
				})
				// complete the association, filling in identifier, uri and lastChangeDateTime; then add to payload
				association.complete_data(this.framework_record.json.CFDocument)
				payload.CFAssociations = [association.to_json()]

			} else {
				// for updating an existing rubric, we don't need to change the association
				payload.CFRubrics = [rubric.to_json_for_update()]
			}

			// console.log(payload); return;

            this.$store.dispatch('save_framework_data', payload).then(response => {
				// once the save_framework_data service completes successfully, we need to update the framework's data model in the $store

				// update timestamps with the value sent back from the server, then update or push to store...
				// for now at least (12/5/2024), the server will update the dates of both the rubric and all criteria and levels on every save
				rubric.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
				for (let rc of rubric.CFRubricCriteria) {
					rc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					for (let rcl of rc.CFRubricCriterionLevels) {
						rcl.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}
				}

                if (this.is_new_rubric) {
					// push rubric json to store
                    this.$store.commit('set', [this.framework_record.json.CFRubrics, 'PUSH', rubric.to_json()])

					// push association json to store if we saved one
					if (association) {
						association.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
						let assoc_json = association.to_json()
						this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', assoc_json])

						// add association to rubric_associations_hash, and set rubric_association_identifier
						this.framework_record.cfo.rubric_associations_hash[this.item_identifier] = [assoc_json]
						this.framework_record.cfo.rubric_associations_hash[this.rubric_identifier] = [assoc_json]
						this.rubric_association_identifier = assoc_json.identifier
					}

                } else {
					// splice update rubric json to store
					let i = this.framework_record.json.CFRubrics.findIndex(x=>x.identifier == rubric.identifier)
                    this.$store.commit('set', [this.framework_record.json.CFRubrics, 'SPLICE', i, rubric.to_json()])
                }

				// reset the initial state
                this.set_initial_state()                
            })
        },

        close_dialog() {
            this.$emit('update:value', false)
        },

        tabber(event) {
            U.tabber(event)
        },

		delete_rubric() {
			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to delete this Rubric?',
				acceptText: 'Delete',
				acceptColor: 'red darken-2',
			}).then(y => {
				const payload = {
					lsdoc_identifier: this.framework_record.lsdoc_identifier,
					CFRubrics: [{
						identifier: this.rubric_identifier,
						delete: 'yes',
					}],
					CFAssociations: [{
						identifier: this.rubric_association_identifier,
						delete: 'yes',
					}]
				}

				// TODO (TC): if there are any associations to criteria and/or levels of this rubric, those associations need to be removed too

				this.$store.dispatch('save_framework_data', payload).then(response => {
					// once the save_framework_data service completes successfully, we need to update the framework's data model in the $store

					// remove rubric from CFRubrics and rubric association from CFAssociations
					const rubric_index = this.framework_record.json.CFRubrics.findIndex(x=>x.identifier == this.rubric_identifier)
					if (rubric_index > -1) this.$store.commit('set', [this.framework_record.json.CFRubrics, 'SPLICE', rubric_index])					// should always be true
					const association_index = this.framework_record.json.CFAssociations.findIndex(x=>x.identifier == this.rubric_association_identifier)
					if (association_index > -1) this.$store.commit('set', [this.framework_record.json.CFAssociations, 'SPLICE', association_index])	// should always be true

					// also delete rubric association from rubric_associations_hash
					delete this.framework_record.cfo.rubric_associations_hash[this.item_identifier]
					delete this.framework_record.cfo.rubric_associations_hash[this.rubric_association_identifier]

					// TODO (TC): also remove associations to criteria and/or levels from CFAssociations and rubric_associations_hash

					// kill dialog
					this.close_dialog()
				});
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
    },
}
</script>

<style scoped lang="scss">

#k-rubric-editor-dialog-card {
    background-color: white !important;
    border: 1px solid white;

    .k-rubric-editor-dialog-title {
        .k-editor-title {
            width: 100%;
        }
    }

    .k-rubric-editor-dialog-content {
        .spacer {
            height: 32px;
            width: 100%;
        }
    }
}
.k-rubric-editor-required::after {
    content: "*";
    color: red;
}
</style>
