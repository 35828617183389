<template><div class="k-editor-wrapper-outer"><div class="k-editor-wrapper" :class="top_css" :style="top_style_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="cancel_edit"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex">
		<div><v-icon class="mr-1">fas fa-edit</v-icon> {{suggestions_only?'Suggested Item Edits':'Edit Item'}}</div>
		<v-spacer/>
		<v-icon @click="U.show_help(suggestions_only?'suggest_edits':'edit_item')" class="k-editor-info-icon mr-2">fas fa-info-circle</v-icon>
	</div>
	<div class="k-case-item-editor-scrollable k-case-item-editor-scrollable-taller" style="font-size:14px">
		<div class="k-case-ie-line mb-1">
			<div class="k-case-ie-line-label mb-0 mr-2">*Identifier:</div>
			<div v-html="CFItem.identifier"></div>
		</div>
		<div class="k-case-ie-line" v-show="form_active">
			<div style="width:180px" class="mr-3">
				<div class="k-case-ie-line-label">Human-Readable Code:</div>
				<div><v-text-field background-color="#fff" ref="hrc_input" outlined dense hide-details v-model="CFItem.humanCodingScheme" placeholder="" autocomplete="new-password" @focus="$store.commit('set',['last_edited_item_field','hrc_input'])"></v-text-field></div>
			</div>
			<div style="flex:1 0 auto">
				<div class="k-case-ie-line-label">Abbreviated Statement:</div>
				<div><v-text-field background-color="#fff" outlined dense hide-details v-model="CFItem.abbreviatedStatement" placeholder="" autocomplete="new-password" clearable></v-text-field></div>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active">
			<div style="flex:1 1 auto">
				<div class="k-case-ie-line-label d-flex align-end">
					<div>*Full Statement:</div>
					<v-spacer/>
					<div style="font-weight:normal; font-size:12px;">
						<span v-if="suggestions_only">(rich text formatting is only allowed for suggestions)</span>
						<v-btn v-show="show_whitespace_clean_btn('fullStatement')" @click="clean_whitespace('fullStatement')" x-small color="primary" class="k-tight-btn"><v-icon x-small class="mr-1">fas fa-soap</v-icon>Remove Line Breaks</v-btn>
						<v-btn v-show="show_latex_editor_btn('fullStatement')" @click="edit_latex('fullStatement')" x-small color="primary" class="k-nocaps-btn k-tight-btn">{{cursor_latex_expression.fullStatement.latex?'EDIT':'ADD'}} LaTeX</v-btn>
						<v-btn x-small text color="primary" class="k-tight-btn k-nocaps-btn" v-if="render_latex" @click="latex_help">LaTeX HELP <v-icon x-small class="ml-1">fas fa-question</v-icon></v-btn>
					</div>
				</div>
				<v-textarea v-if="!suggestions_only" @keydown.tab.prevent="tabber($event)" @keyup="check_for_latex_position($event,'fullStatement','keyup')" @mouseup="check_for_latex_position($event,'fullStatement','mouseup')" @blur="check_for_latex_position($event,'fullStatement','blur')" @focus="check_for_latex_position($event,'fullStatement','focus');$store.commit('set',['last_edited_item_field','fs_input'])" background-color="#fff" ref="fs_input" outlined dense hide-details v-model="CFItem.fullStatement" placeholder="" rows="2" auto-grow clearable></v-textarea>
				<froala-wrapper v-if="suggestions_only" :config="suggestions_editor_config()" v-model="CFItem.fullStatement" />
				<div v-if="show_preview('fullStatement')" class="k-editor-markup-preview">
					<div class="k-case-ie-line-label ml-0 mb-1 grey--text text--darken-2"><i>Full Statement Preview:</i></div>
					<div v-html="preview_html('fullStatement')"></div>
				</div>
			</div>
		</div>
		<div class="mb-3" style="margin-top:-4px; background-color:#eee; border-radius:6px; padding:4px;" v-if="!suggestions_only&&item_is_copy&&changes_pending" v-show="form_active">
			<!-- <div style="font-size:13px; line-height:17px" class="text-center"><i>This item is currently tagged as a copy of another item. <br>If you edit the Full Statement of this item, this tag will be cleared.</i></div> -->
			<div style="font-size:13px; line-height:17px" class="text-center"><i>This item is currently tagged as a copy of another item.</i></div>
			<div class="mt-1"><v-checkbox class="mt-0 pt-0" v-model="break_sourceItemIdentifier" hide-details><template v-slot:label><span style="font-size:13px">Remove this tag, breaking the link with the original item</span></template></v-checkbox></div>
			<div class="mt-1" v-if="break_sourceItemIdentifier"><v-checkbox class="mt-0 pt-0" v-model="create_related_to" hide-details><template v-slot:label><span style="font-size:13px">Add a “Related To” association from this item to the item it was originally copied from</span></template></v-checkbox></div>
		</div>
		<!-- PW: disabling this for now at least... -->
		<div class="mb-3 text-center" style="margin-top:-4px; background-color:#eee; border-radius:6px; padding:4px; font-size:13px; line-height:17px" v-if="false && !suggestions_only&&item_is_original&&changes_pending&&!item_copies_in_this_framework" v-show="form_active">
			<div v-if="!item_copies_in_this_framework"><i>Note: This item may have been copied to this or another framework.</i></div>
			<!-- <div v-if="!item_copies_in_this_framework"><i>Note: This item may have been copied to other frameworks. If so, copies of the item in other frameworks will also be updated when you save your edits.</i></div>
			<div v-else><i>Note: Editing this item will also update {{item_copies_in_this_framework}} of this item elsewhere in this framework. If there are copies of the item in other frameworks, those copies will also be updated.</i></div> -->
		</div>

		<div class="k-case-ie-line" v-show="form_active">
			<div style="flex:1 1 auto">
				<div class="k-case-ie-line-label d-flex align-end">
					<div>Notes:</div>
					<v-spacer/>
					<div style="font-weight:normal; font-size:12px;">
						<span v-if="suggestions_only">(rich text formatting is only allowed for suggestions)</span>
						<v-btn v-show="show_whitespace_clean_btn('notes')" @click="clean_whitespace('notes')" x-small color="primary" class="k-tight-btn"><v-icon x-small class="mr-1">fas fa-soap</v-icon>Remove Line Breaks</v-btn>
						<v-btn v-show="show_latex_editor_btn('notes')" @click="edit_latex('notes')" x-small color="primary" class="k-nocaps-btn k-tight-btn">{{cursor_latex_expression.notes.latex?'EDIT':'ADD'}} LaTeX</v-btn>
						<!-- <v-btn v-show="cursor_latex_expression.notes&&cursor_latex_expression.notes.latex&&last_edited_item_field=='fs_input'" @click="edit_latex('notes')" x-small color="primary" class="k-nocaps-btn k-tight-btn">EDIT LaTeX</v-btn>
						<v-btn v-show="render_latex&&last_edited_item_field=='notes_input'&&(!cursor_latex_expression.notes||!cursor_latex_expression.notes.latex)" @click="edit_latex('notes')" x-small color="primary" class="k-nocaps-btn k-tight-btn">ADD LaTeX</v-btn> -->
						<span class="ml-2" style="font-weight:normal; font-size:12px;">(<span v-if="suggestions_only">rich text formatting is only allowed for suggestions; </span>use <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">markdown</a> if you wish)</span>
					</div>
				</div>

				<!-- <div class="k-case-ie-line-label">Notes: <div class="float-right" style="font-weight:normal; font-size:12px;">(<span v-if="suggestions_only">rich text formatting is only allowed for suggestions; </span>use <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">markdown</a> if you wish)</div></div> -->
				<v-textarea v-if="!suggestions_only" @keydown.tab.prevent="tabber($event)" @keyup="check_for_latex_position($event,'notes','keyup')" @mouseup="check_for_latex_position($event,'notes','mouseup')" @blur="check_for_latex_position($event,'notes','blur')" @focus="check_for_latex_position($event,'notes','focus');$store.commit('set',['last_edited_item_field','notes_input'])" background-color="#fff" ref="notes_input" outlined dense hide-details v-model="CFItem.notes" placeholder="" rows="1" auto-grow clearable></v-textarea>
				<froala-wrapper v-if="suggestions_only" :config="suggestions_editor_config()" v-model="CFItem.notes" />
				<div v-if="show_preview('notes')" class="k-editor-markup-preview">
					<div class="k-case-ie-line-label ml-0 mb-1 grey--text text--darken-2"><i>Notes Preview:</i></div>
					<div v-html="preview_html('notes')"></div>
				</div>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active&&CFItem.CFItemType!='Metadata Category'">
			<div style="flex:1 1 auto">
				<div class="k-case-ie-line-label pb-1">
					Supplemental Information: 
					
					<div class="float-right"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" color="primary" v-model="use_html_for_supplementalNotes" mandatory>
						<v-btn x-small light :value="false" class="k-nocaps-btn k-tight-btn" @click.stop="">Markdown</v-btn>
						<v-btn x-small light :value="true" @click.stop="">HTML</v-btn>
					</v-btn-toggle></div>
				</div>
				<froala-wrapper v-if="use_html_for_supplementalNotes" :config="supplementalNotes_editor_config()" v-model="CFItem.extensions.supplementalNotes" />
				<v-textarea v-if="!use_html_for_supplementalNotes" @keydown.tab.prevent="tabber($event)" background-color="#fff" ref="supplementalNotes_input" outlined dense hide-details v-model="CFItem.extensions.supplementalNotes" placeholder="" rows="1" auto-grow clearable @focus="$store.commit('set',['last_edited_item_field','supplementalNotes_input'])"></v-textarea>
				<!-- currently not showing preview here because it's wysiwyg! -->
				<!-- <div v-if="show_preview('supplementalNotes')" class="k-editor-markup-preview">
					<div class="k-case-ie-line-label ml-0 mb-1 grey--text text--darken-2"><i>Supplemental Information Preview:</i></div>
					<div v-html="preview_html('supplementalNotes')"></div>
				</div> -->
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active">
			<div style="flex:1 1 150px">
				<div class="k-case-ie-line-label">Item Type:</div>
				<div><v-select background-color="#fff" v-model="CFItem.CFItemType" :items="item_types" label="" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
			</div>

			<div style="flex:1 1 150px" class="mx-3" v-show="CFItem.CFItemType!='Metadata Category'">
				<div class="k-case-ie-line-label">Language:</div>
				<div><v-select background-color="#fff" v-model="CFItem.language" :items="languages" label="" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
			</div>

			<div style="flex:1 1 164px" v-show="CFItem.CFItemType!='Metadata Category'">
				<div class="k-case-ie-line-label">Education Level:</div>
				<div class="d-flex">
					<div style="flex:1 1 50%"><v-select background-color="#fff" v-model="grade_low" :items="grades" label="Low" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
					<div class="ml-1" style="flex:1 1 50%"><v-select background-color="#fff" v-model="grade_high" :items="grades" label="High" outlined dense hide-details :menu-props="{top:true}"></v-select></div>
				</div>
			</div>
		</div>

		<div class="k-case-ie-line" v-show="form_active">
			<div style="flex:1 1 50%">
				<div class="k-case-ie-line-label">Implementation Start Date:</div>
				<div class="mr-2">
					<v-menu ref="statusStartDateMenu" v-model="statusStartDateMenu" :close-on-content-click="false" :return-value.sync="CFItem.statusStartDate" transition="scale-transition" offset-y min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-on="on" v-bind="attrs" background-color="#fff" v-model="CFItem.statusStartDate" label="" outlined dense hide-details clearable></v-text-field>
						</template>
						<v-date-picker v-model="CFItem.statusStartDate" no-title scrollable @input="$refs.statusStartDateMenu.save(CFItem.statusStartDate)">
							<v-btn v-show="CFItem.statusStartDate" small text color="red darken-2" @click="$refs.statusStartDateMenu.save('')"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Clear</v-btn>
							<v-spacer></v-spacer>
							<v-btn small text color="primary" @click="statusStartDateMenu=false"><v-icon small class="mr-1">fas fa-circle-xmark</v-icon>Close</v-btn>
						</v-date-picker>
					</v-menu>					
				</div>
			</div>

			<div style="flex:1 1 50%">
				<div class="k-case-ie-line-label ml-3">Retirement Date:</div>
				<div class="ml-2">
					<v-menu ref="statusEndDateMenu" v-model="statusEndDateMenu" :close-on-content-click="false" :return-value.sync="CFItem.statusEndDate" transition="scale-transition" offset-y min-width="auto">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-on="on" v-bind="attrs" background-color="#fff" v-model="CFItem.statusEndDate" label="" outlined dense hide-details clearable></v-text-field>
						</template>
						<v-date-picker v-model="CFItem.statusEndDate" no-title scrollable @input="$refs.statusEndDateMenu.save(CFItem.statusEndDate)">
							<v-btn v-show="CFItem.statusEndDate" small text color="red darken-2" @click="$refs.statusEndDateMenu.save('')"><v-icon small class="mr-1">fas fa-trash-alt</v-icon>Clear</v-btn>
							<v-spacer></v-spacer>
							<v-btn small text color="primary" @click="statusEndDateMenu=false"><v-icon small class="mr-1">fas fa-circle-xmark</v-icon>Close</v-btn>
						</v-date-picker>
					</v-menu>					
				</div>
			</div>
		</div>

		<div v-if="user_can_admin" class="k-case-ie-line mb-1" v-show="form_active">
			<v-spacer/>
			<div style="flex:1 1 100px">
				<v-checkbox class="mt-0 pt-0 d-inline-block" style="vertical-align:bottom" v-model="CFItem.extensions.isSupplementalItem" hide-details><template v-slot:label><nobr style="font-size:14px">Supplemental branch <i class="ml-1">(advanced setting)</i><v-icon small color="light-blue" class="ml-2" @click.stop="show_help('supplemental_branches')">fas fa-info-circle</v-icon></nobr></template></v-checkbox>
			</div>
			<v-spacer/>
		</div>

		<div v-if="custom_fields" v-show="form_active">
			<div><b>Custom fields:</b></div>
			<div v-for="(def, key) in custom_fields" :key="key" class="mt-1 ml-4 d-flex align-center">
				<v-tooltip top><template v-slot:activator="{on}"><v-icon v-on="on" small color="light-blue" class="mr-1" style="margin-top:-3px">fas fa-info-circle</v-icon></template><div style="max-width:500px"><b>{{key}}:</b> <span v-html="def.description"></span></div></v-tooltip>
				<div class="k-case-ie-line-label mr-2">{{def.display_key ? def.display_key : key}}:</div>
				<div v-if="def.type=='boolean'" style="flex:1 1 auto"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="CFItem.extensions[key]"></v-checkbox></div>
				<div v-if="def.type=='string'&&def.legal_vals.length==0" style="flex:1 1 auto"><v-text-field background-color="#fff" outlined dense hide-details v-model="CFItem.extensions[key]" placeholder="" clearable></v-text-field></div>
				<div v-if="def.type=='string'&&def.legal_vals.length>0" style="flex:1 1 auto"><v-select v-model="CFItem.extensions[key]" :items="def.legal_vals" dense outlined hide-details></v-select></div>
				<div v-if="def.type=='array'&&def.legal_vals.length==0" style="flex:1 1 auto"><v-text-field background-color="#fff" outlined dense hide-details v-model="CFItem.extensions[key]" placeholder="" clearable></v-text-field></div>
				<div v-if="def.type=='array'&&def.legal_vals.length>0" style="flex:1 1 auto"><v-select v-model="CFItem.extensions[key]" :items="def.legal_vals" multiple dense outlined hide-details></v-select></div>
			</div>
		</div>

		<ItemImportInterface v-show="text_import_interface_showing" :educationLevel="CFItem.educationLevel" :language="CFItem.language" @cancel_import="text_import_interface_showing=false" :editor_component="this" />
		<ItemCSVImportInterface v-show="csv_import_interface_showing" :educationLevel="CFItem.educationLevel" :language="CFItem.language" @cancel_import="csv_import_interface_showing=false" :editor_component="this" />
		<ItemCopyInterface v-show="copy_interface_showing" :framework_record="framework_record" :node_being_edited="original_node" @cancel_copy="copy_interface_showing=false" />
	</div>

	<div class="k-case-item-editor-buttons" v-show="form_active">
		<div v-if="!is_new_item&&!suggestions_only">
			<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" x-small fab color="secondary" @click="add_sibling"><v-icon small>fas fa-down-long</v-icon></v-btn></template>Create a new SIBLING item(⌘⇧0)</v-tooltip>
			<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" x-small fab class="mr-1" color="secondary" @click="add_child"><v-icon small style="transform:rotate(90deg);">fas fa-turn-up</v-icon></v-btn></template>Create a new CHILD item (⌘⇧9)</v-tooltip>
			<!-- Note that this menu should be kept in synch with the analogous menu in CASEItemTile -->
			<v-menu :transition="false" top center offset-y v-model="edit_actions_menu_showing"><template v-slot:activator="{on}"><v-btn v-if="!is_new_item" v-on="on" small color="secondary" small><v-icon small class="mr-2">fas fa-ellipsis-v</v-icon>More Actions</v-btn></template>
				<v-list dense>
					<v-menu :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
						<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
							<v-list-item-icon><v-icon small>fas fa-plus</v-icon></v-list-item-icon><v-list-item-title>Create/Copy/Import</v-list-item-title>
							<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
						</v-list-item></template>

						<v-list dense>
							<v-list-item @click="add_sibling"><v-list-item-icon><v-icon small>fas fa-down-long</v-icon></v-list-item-icon><v-list-item-title>Create a <b>new sibling</b> item (⌘⇧0)</v-list-item-title></v-list-item>
							<v-list-item @click="add_child"><v-list-item-icon><v-icon small style="transform:rotate(90deg);">fas fa-turn-up</v-icon></v-list-item-icon><v-list-item-title>Create a <b>new child</b> item (⌘⇧9)</v-list-item-title></v-list-item>
							<v-list-item @click="show_copy_interface"><v-list-item-icon><v-icon small>far fa-copy</v-icon></v-list-item-icon><v-list-item-title><b>Copy</b> child items from this or another framework (⌘⇧C)</v-list-item-title></v-list-item>
							<v-list-item @click="show_text_import_interface"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> child items from <b>text input</b> (⌘⇧I)</v-list-item-title></v-list-item>
							<v-list-item @click="show_csv_import_interface"><v-list-item-icon><v-icon small>fas fa-file-csv</v-icon></v-list-item-icon><v-list-item-title>Batch <b>import</b> child items from <b>CSV file</b></v-list-item-title></v-list-item>
						</v-list>
					</v-menu>

					<v-menu v-if="original_node.children.length>0" :transition="false" offset-x nudge-top="8" :open-on-hover="false" style="display: block;">
						<template v-slot:activator="{on}"><v-list-item v-on="on" style="cursor:pointer">
							<v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete</v-list-item-title>
							<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
						</v-list-item></template>

						<v-list dense>
							<v-list-item @click="delete_item"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item and its child items</v-list-item-title></v-list-item>
							<v-list-item @click="delete_children"><v-list-item-icon><v-icon small>far fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item’s child items but preserve this item</v-list-item-title></v-list-item>
							<v-list-item @click="delete_item_not_children"><v-list-item-icon><v-icon small>far fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item but preserve child items</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
					<v-list-item v-if="original_node.children.length==0" @click="delete_item"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete this item</v-list-item-title></v-list-item>
					<v-list-item v-if="original_node.children.length>0" @click="viewer.sort_children(original_node)"><v-list-item-icon><v-icon small>fas fa-arrow-down-short-wide</v-icon></v-list-item-icon><v-list-item-title>Sort child items</v-list-item-title></v-list-item>

					<v-divider/>
					<v-list-item @click="viewer.toggle_move_mode"><v-list-item-icon><v-icon small>fas fa-arrows-alt</v-icon></v-list-item-icon><v-list-item-title>Move items (⌘⇧.)</v-list-item-title></v-list-item>
					<v-list-item @click="viewer.toggle_batch_edit_mode"><v-list-item-icon><v-icon small>fas fa-wand-magic-sparkles</v-icon></v-list-item-icon><v-list-item-title>Batch update items</v-list-item-title></v-list-item>
					<v-list-item @click="viewer.toggle_make_associations"><v-list-item-icon><v-icon small>fas fa-arrows-alt-h</v-icon></v-list-item-icon><v-list-item-title>Make associations</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</div>

		<v-spacer></v-spacer>
		<v-btn small v-if="is_new_item||changes_pending||suggestions_only" color="secondary" @click="cancel_edit">Cancel</v-btn>
		<v-btn small v-if="(changes_pending||!is_new_item)&&!suggestions_only" color="primary" @click="save_changes" class="ml-2">
			<span v-if="changes_pending"><v-icon small class="mr-2">fas fa-save</v-icon>Save Changes</span>
			<span v-else>Done Editing</span>
		</v-btn>
		<v-btn small v-if="changes_pending&&suggestions_only" color="primary" @click="save_suggestions" class="ml-2">
			<v-icon small class="mr-2">fas fa-save</v-icon>Save Suggested Edits
		</v-btn>
	</div>

</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ItemImportInterface from './ItemImportInterface'
import ItemCSVImportInterface from './ItemCSVImportInterface'
import ItemCopyInterface from './ItemCopyInterface'

export default {
	components: { ItemImportInterface, ItemCSVImportInterface, ItemCopyInterface },
	props: {
		framework_record: { type: Object, required: true },
		original_node: { type: Object, required: true },
		framework_maximized: { type: Boolean, required: false, default() { return false }},
		viewer: { required: false, default() { return null }},
	},
	data() { return {
		editor_type: 'item',	// used by ItemImportInterface / CopyInterface
		CFItem: {},
		original_CFItem: '',
		original_fullStatement: '',
		original_humanCodingScheme: '',
		break_sourceItemIdentifier: false,
		create_related_to: false,
		edit_actions_menu_showing: false,
		text_import_interface_showing: false,
		csv_import_interface_showing: false,
		copy_interface_showing: false,
		last_clicked_node_before_editing: '',
		suggestions_only: false,	// if this gets set to true, we don't record any changes to the item (see save_suggestions)
		applying_suggestions: false,	// if this gets set to true, we call a callback on save
		statusStartDateMenu: false,
		statusEndDateMenu: false,

		// used to track latex expressions in fullStatement and notes fields
		cursor_latex_expression: {fullStatement: false, notes: false},
	}},
	computed: {
		...mapState(['languages', 'grades', 'last_edited_item_field']),
		...mapGetters([]),
		cfo() { return this.framework_record.cfo },
		lsdoc_identifier() { return this.framework_record.lsdoc_identifier },
		CFDocument() { return this.framework_record.json.CFDocument },
		item_identifier() { return this.original_node.cfitem.identifier },
		is_new_item() { return !empty(this.original_node.new_item_parent_node)},
		render_latex() { return this.framework_record.ss_framework_data.render_latex },
		changes_pending() {
			return (JSON.stringify(this.CFItem.to_json()) != this.original_CFItem)
		},
		form_active() {
			if (this.text_import_interface_showing || this.csv_import_interface_showing || this.copy_interface_showing) return false
			return true
		},
		item_types() {
			// this is largely repeated in BatchEditor
			let arr = []

			// start with types in the document (these will be in alphabetical order)
			for (let t of this.cfo.item_types) {
				arr.push({value:t, text:t})
			}

			// then add additional types in standard_item_types that aren't already in the framework (this will include '-')
			for (let t of this.$store.state.standard_item_types) {
				if (!this.cfo.item_types.find(x=>x==t.value)) arr.push({value:t.value, text:t.text})
			}

			// finally add an option for defining a new type
			arr.push({value: '*NEW*', text:'-- ADD A NEW ITEM TYPE --'})

			return arr
		},
		grade_low: {
			get() {
				if (this.CFItem.educationLevel.length == 0) return ''
				let el = this.CFItem.educationLevel[0]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new lower-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current upper-limit education level
				let el_high = this.CFItem.educationLevel[this.CFItem.educationLevel.length - 1]
				let el_high_index = this.grades.findIndex(g => { return (g.value == el_high || ( !isNaN(el_high*1) && (g.value*1 == el_high*1) )) })

				// if el_high_index is < new_el_index,
				if (el_high_index < new_el_index) {
					// if 9th grade was chosen, default to 12, because most HS courses are 9-12
					if (new_el == '09') el_high_index = this.grades.findIndex(g=>g.value=='12')
					// else just include new_el_index
					else el_high_index = new_el_index
				}

				// include all grades between new_el and el_high
				let arr = []
				for (let i = new_el_index; i <= el_high_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.CFItem.educationLevel = arr
			}
		},
		grade_high: {
			get() {
				if (this.CFItem.educationLevel.length == 0) return ''
				let el = this.CFItem.educationLevel[this.CFItem.educationLevel.length-1]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new upper-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current lower-limit education level
				let el_low = this.CFItem.educationLevel[0]
				let el_low_index = this.grades.findIndex(g => { return (g.value == el_low || ( !isNaN(el_low*1) && (g.value*1 == el_low*1) )) })

				// if el_low_index is > new_el_index, just include new_el_index
				if (el_low_index > new_el_index) el_low_index = new_el_index

				// include all grades between new_el and el_high
				let arr = []
				for (let i = el_low_index; i <= new_el_index; ++i) {
					arr.push(this.grades[i].value)
				}
				this.CFItem.educationLevel = arr
			}
		},
		top_css() {
			const framework_color = U.framework_color(this.CFDocument.identifier) + '-editor'
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-editor'
			return ''
		},
		top_style_css() {
			return U.framework_color_object(this.CFDocument.identifier, 'editor')
		},
		item_is_copy() {
			// an item is a copy of another item if it has a sourceItemIdentifier that isn't identical to the identifier
			return this.CFItem.extensions.sourceItemIdentifier && this.CFItem.extensions.sourceItemIdentifier != this.CFItem.identifier
		},
		item_is_original() {
			// an item may have been copied if its sourceItemIdentifier == its identifier
			return this.CFItem.extensions.sourceItemIdentifier == this.CFItem.identifier
		},
		item_copies_in_this_framework() {
			if (!this.item_is_original) return false
			let n = 0
			for (let item of this.framework_record.json.CFItems) {
				let sourceItemIdentifier = item.sourceItemIdentifier ?? item.extensions?.sourceItemIdentifier
				if (item.identifier != this.CFItem.identifier && sourceItemIdentifier == this.CFItem.identifier) ++n
			}
			return n + ' ' + U.ps('copy', n, 'copies')
		},
		user_can_admin() {
			return vapp.is_granted('admin_framework', this.lsdoc_identifier)
		},
		use_html_for_supplementalNotes: {
			get() { return this.$store.state.lst.use_html_for_supplementalNotes },
			set(val) { this.$store.commit('lst_set', ['use_html_for_supplementalNotes', val]) }
		},
		custom_fields() {
			if (!window.CASE_Custom_Extension_Fields.CFItem) return null

			// return custom_fields for this item type
			let o = {}
			for (let key in window.CASE_Custom_Extension_Fields.CFItem) {
				let f = window.CASE_Custom_Extension_Fields.CFItem[key]
				// if applicableItemTypes is empty/has no values, apply to all; note that to apply to items with no type designated, use applicableItemTypes => ['']
				if (empty(f.applicableItemTypes) || f.applicableItemTypes.length == 0 || f.applicableItemTypes.includes(this.CFItem.CFItemType)) {
					o[key] = f
				}
			}
			if (!U.object_has_keys(o)) return null
			return o
		},
	},
	watch: {
		// update some data as it's typed, so the user sees it updating in real time
		// don't do updates-as-they're-typed if we're in suggestions_only mode
		'CFItem.fullStatement': function() {
			if (this.suggestions_only) return
			this.$store.commit('set', [this.original_node.cfitem, 'fullStatement', this.CFItem.fullStatement])
		},
		'CFItem.humanCodingScheme': function() {
			if (this.suggestions_only) return
			this.$store.commit('set', [this.original_node.cfitem, 'humanCodingScheme', this.CFItem.humanCodingScheme])
		},
		'CFItem.abbreviatedStatement': function() {
			if (this.suggestions_only) return
			this.$store.commit('set', [this.original_node.cfitem, 'abbreviatedStatement', this.CFItem.abbreviatedStatement])
		},
		// deal with item types
		'CFItem.CFItemType': function() {
			// user says they want a new type
			if (this.CFItem.CFItemType == '*NEW*') {
				this.$prompt({
					title: 'New Item Type',
					text: 'Enter your new item type:',
					acceptText: 'OK',
				}).then(t => {
					this.CFItem.CFItemType = ''
					t = $.trim(t)
					if (!empty(t)) {
						// see if this type already exists, possibly with a different case
						let eit = this.item_types.find(x=>x.value.toLowerCase() == t.toLowerCase())
						if (eit) {
							// if so, set to the existing type
							this.$alert('That item type has already been specified.')
							this.CFItem.CFItemType = eit.value
							return
						} else {
							// else set to the specified type
							this.CFItem.CFItemType = t

							// and add that type to cfo
							this.$store.commit('set', [this.cfo.item_types, 'PUSH', t])
						}
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})

			// else if not set to empty...
			} else if (this.CFItem.CFItemType != '') {
				// if user chose one of the standard types that wasn't already somewhere else in the framework, add the type to cfo
				if (!this.cfo.item_types.find(x=>x.toLowerCase() == this.CFItem.CFItemType.toLowerCase())) {
					this.$store.commit('set', [this.cfo.item_types, 'PUSH', this.CFItem.CFItemType])
				}
			}
		},
	},
	created() {
		console.log('created', this.original_node.cfitem)
		// save a snapshot of the properties in the original cfitem to use in the editor form, then capture a stringified version for use in changes_pending
		this.CFItem = new CFItem(this.original_node.cfitem)

		// the CASE spec (unfortunately) allows for an item's type to be in either CFItemType or CFItemTypeURI.title; if they don't match, clear CFItemTypeURI
		this.CFItem.CFItemType = U.item_type_string(this.CFItem)
		if (this.CFItem.CFItemTypeURI?.title != this.CFItem.CFItemType) this.CFItem.CFItemTypeURI = new LinkURI()

		this.original_CFItem = JSON.stringify(this.CFItem.to_json())
		this.original_fullStatement = this.CFItem.fullStatement
		this.original_humanCodingScheme = this.CFItem.humanCodingScheme

		// if this is a new item, remember last_clicked_node_before_editing in case we cancel, then clear last_clicked_node
		if (this.is_new_item) {
			this.last_clicked_node_before_editing = this.framework_record.last_clicked_node
			this.$store.commit('set', [this.framework_record, 'last_clicked_node', ''])

			// also make the new node active, and make sure its parents are open
			this.$emit('make_node_active', this.original_node.tree_key, true)
			this.$emit('make_node_parents_open', this.original_node.tree_key)
		}

		// in preparation for the eventuality that we might add latex for the first time here, initialize MathJax (this isn't a lot of overhead)
		U.mathjax.initialize()

		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		this.viewer.current_editor = this
	},
	mounted() {
		setTimeout(x=>{
			let last_edited_item_field = this.$store.state.last_edited_item_field
			if (empty(this.$refs[last_edited_item_field]) || empty(this.$refs[last_edited_item_field].$el)) return
			if (last_edited_item_field == 'hrc_input') {
				$(this.$refs[last_edited_item_field].$el).find('input').focus()
			} else {
				$(this.$refs[last_edited_item_field].$el).find('textarea').focus()
			}
		}, 50)
	},
	methods: {
		show_help(doc) { vapp.show_help(doc) },

		tabber(event) { U.tabber(event) },

		show_latex_editor_btn(field) {
			// only show the latex editor btn for a field if we're editing that field
			if (this.last_edited_item_field != ((field=='fullStatement')?'fs_input':'notes_input')) return false

			// this shouldn't happen, but check in case
			if (!this.cursor_latex_expression[field]) return false

			// if we get to here and the `Provide LaTeX editing support` option is on for the document, always show the btn
			if (this.render_latex) return true

			// otherwise show the btn if cursor_latex_expression[field].latex is non-empty
			return !empty(this.cursor_latex_expression[field].latex)
		},

		show_whitespace_clean_btn(field) {
			return this.CFItem[field].includes('\n')
		},

		clean_whitespace(field) {
			// rather than automatically use the U.remove_line_breaks_from_text_field fn when we save, as we used to do, allow for a button the user can click to clear line breaks if they wish;
			// if they click this btn, collapse all spacing, disregarding any effects on markdown
			this.CFItem[field] = $.trim(this.CFItem[field].replace(/\s+/g, ' '))
		},

		supplementalNotes_editor_config(enter) {
			let config = U.get_froala_config({
				enter: FroalaEditor.ENTER_P,
				editorClass: 'k-case-tree-markdown',
				// heightMin: 350,
				// zIndex: 2501,
			})

			// for some reason this doesn't seem to work...
			if (this.suggestions_only) {
				config.toolbarButtons.moreRich.buttons.unshift('mark_delete')
				config.toolbarButtons.moreRich.buttons.unshift('mark_add')
				config.toolbarButtons.moreRich.buttons.unshift('mark_highlight')
				config.toolbarButtons.moreRich.buttons.buttonsVisible += 3
			}

			return config
		},

		suggestions_editor_config() {
			let config = U.get_froala_config({
				placeholderText: '',
				enter: FroalaEditor.ENTER_BR,
			})

			config.toolbarButtons.moreRich = {buttons: ['mark_highlight', 'mark_add', 'mark_delete', 'insertLink', 'insertImage', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'align',] , buttonsVisible: 5}
			return config
		},

		cancel_edit() {
			// revert fullStatement, humanCodingScheme, and abbreviatedStatement in case they were changed
			// unless we're in suggestions_only mode, in which case we don't change these as they're typed anyway
			if (!this.suggestions_only) {
				let original_json = JSON.parse(this.original_CFItem)
				this.$store.commit('set', [this.original_node.cfitem, 'fullStatement', original_json.fullStatement])
				this.$store.commit('set', [this.original_node.cfitem, 'humanCodingScheme', original_json.humanCodingScheme])
				this.$store.commit('set', [this.original_node.cfitem, 'abbreviatedStatement', original_json.abbreviatedStatement])
			}

			// if this is a new item, delete it from the framework
			if (this.is_new_item) {
				U.delete_node_from_cfo(this.cfo, this.original_node)

				// revert to the previously-clicked node
				this.$store.commit('set', [this.framework_record, 'active_node', this.last_clicked_node_before_editing])
			}

			this.$emit('dialog_cancel')
		},

		apply_previous_suggested_edits(previous_suggested_edits) {
			// transfer previous suggested edits to CFItem (which is a *copy* of the actual json)
			for (let key in previous_suggested_edits) {
				let val = (previous_suggested_edits[key] === '*CLEAR*' ? '' : previous_suggested_edits[key])
				// if we're not in suggestions_only mode, we need to strip html from fullStatement and notes
				if (!this.suggestions_only) {
					// for proposed deletions, enclose in square braces
					if (typeof(val) == 'string') val = val.replace(/<span class="k-fr-mark_delete-text">(.*?)<\/span>/g, '[$1]')

					if (key == 'fullStatement' || key == 'notes') {
						val = U.html_to_text(val)
					}
				}

				// for LinkURI's, have to create LinkURI objects
				if (typeof(val) == 'object') {
					if (val.hasOwnProperty('uri')) this.CFItem[key] = new LinkURI(val)
					else {
						console.log('POSSIBLE BAD PROPERTY IN apply_previous_suggested_edits: ' + key, val)
						this.CFItem[key] = val
					}
				} else {
					this.CFItem[key] = val
				}
			}
		},

		enter_suggestions_only_mode(previous_suggested_edits) {
			this.suggestions_only = true
			// the commenting system uses this fn to enter "suggestions only" mode, passing in previous suggested edits if there are any
			if (!empty(previous_suggested_edits) && typeof(previous_suggested_edits) == 'object') {
				this.apply_previous_suggested_edits(previous_suggested_edits)

				// re-record original_CFItem
				this.original_CFItem = JSON.stringify(this.CFItem.to_json())
			}
		},

		apply_suggestions(previous_suggested_edits) {
			// a call to this fn will be originated from a comment where someone made suggested edits.
			// we apply the previous suggested edits just as in enter_suggestions_only_mode, but *don't* change the original_xxx
			// then we let the user save, or cancel, as if they had entered the changes themselves
			this.apply_previous_suggested_edits(previous_suggested_edits)

			// note that we're applying suggestions so that if the user clicks save, we callback to mark the suggestions as accepted
			this.applying_suggestions = true
		},

		// user is only making suggestions, and clicks to save
		save_suggestions() {
			// remove CFItemTypeURI; if this gets changed we will be storing it in CFItemType
			delete this.CFItem.CFItemTypeURI

			this.$emit('item_edit_suggestion_saved', this.CFItem)
		},

		save_changes(flag) {
			// keyboard shortcut cmd-shift-D calls save_changes('and_done'), which says to close the editor after saving

			let changes_pending_was_true = this.changes_pending

			// record original_lastChangeDateTime in case we need it below
			let original_lastChangeDateTime = this.CFItem.lastChangeDateTime

			// trim inputted values; put values that can have markdown through U.remove_line_breaks_from_text_field
			// this.CFItem.fullStatement = U.remove_line_breaks_from_text_field(this.CFItem.fullStatement)
			this.CFItem.fullStatement = $.trim(this.CFItem.fullStatement)
			this.CFItem.abbreviatedStatement = $.trim(this.CFItem.abbreviatedStatement)
			this.CFItem.humanCodingScheme = $.trim(this.CFItem.humanCodingScheme)
			// this.CFItem.notes = U.remove_line_breaks_from_text_field(this.CFItem.notes)
			this.CFItem.notes = $.trim(this.CFItem.notes)

			// for supplementalNotes, trim leading/trailing line breaks using trim_froala_text function
			this.CFItem.extensions.supplementalNotes = U.trim_froala_text(this.CFItem.extensions.supplementalNotes)

			// do the same check as changes_pending, but do it directly since we just updated CFItem
			if (JSON.stringify(this.CFItem.to_json()) == this.original_CFItem) {
				// if changes_pending was already not true, cancel_edit
				if (!changes_pending_was_true) {
					this.cancel_edit()
				}
				return
			}

			if (empty(this.CFItem.fullStatement)) {
				this.$alert('All CASE items must specify a full statement.')
				return
			}

			// complete the item, filling in identifier, uri, and lastChangeDateTime
			this.CFItem.complete_data(this.CFDocument)

			let json_to_save
			if (this.is_new_item) {
				// if this is a new item, save the "normal" json
				json_to_save = this.CFItem.to_json()
			} else {
				// for an update, use to_json_for_update to set values of unset optional parameters to '*CLEAR*'
				json_to_save = this.CFItem.to_json_for_update()
			}

			let data = {
				update_item_types: true,	// this will trigger the save_framework_data dispatch fn to deal with CFItemTypes
				lsdoc_identifier: this.lsdoc_identifier,
				CFItems: [
					json_to_save
				],
				CFAssociations: [],
			}

			// if this is a newly-added item...
			if (this.is_new_item) {
				// create an 'isChildOf' association for the item, and add to data
				// note that new nodes will always be carefully created with the to-be-used sequenceNumber as node.sequence
				let child_association = U.create_association(this.original_node.new_item_parent_node.cfitem, this.CFItem, this.original_node.sequence, this.CFDocument)
				data.CFAssociations.push(child_association.to_json())

			} else {
				// if the humancodingscheme or fullstatement changed, update CFAssociations.nodeURI.title values if necessary
				let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, this.original_node.cfitem.identifier, this.original_node.parent_node.cfitem.identifier)
				if (index == -1) { this.$alert('Error'); return; }	// this shouldn't happen
				let cfa = this.framework_record.json.CFAssociations[index]
				let new_cfa_title = U.generate_cfassociation_node_uri_title(this.CFItem)
				if (cfa.originNodeURI.title != new_cfa_title) {
					this.$store.commit('set', [cfa.originNodeURI, 'title', new_cfa_title])
					data.CFAssociations.push(new CFAssociation(cfa).to_json())
				}

				// if the item is a copy of another item and something changed and the break_sourceItemIdentifier checkbox was selected...
				if (this.item_is_copy && this.changes_pending && this.break_sourceItemIdentifier) {
					// if user selected to insert an association, do so
					if (this.create_related_to) {
						// first make sure the association doesn't already exist
						let assoc_check = this.framework_record.json.CFAssociations.find(x=>
							x.associationType == 'isRelatedTo' && (
								   (x.originNodeURI.identifier == this.CFItem.identifier && x.destinationNodeURI.identifier == this.CFItem.extensions.sourceItemIdentifier)
								|| (x.originNodeURI.identifier == this.CFItem.extensions.sourceItemIdentifier && x.destinationNodeURI.identifier == this.CFItem.identifier)
							)
						)
						if (!assoc_check) {
							// if it's not already there, create it
							// this code is similar to that in MakeAssociationsInterface
							// for constructing the destination title, try to get destItem from sourceItemIdentifier in this framework
							let destItem = this.framework_record.cfo.cfitems[this.CFItem.extensions.sourceItemIdentifier]
							if (!destItem) {
								// if not there, construct a "fake" CFItem with the original fullStatement to use for the title
								destItem = new CFItem({fullStatement: this.original_fullStatement})
							}

							// for constructing the destination title, if the sourceItemIdentifier is in this framework, we can use it to do what we do when we make other assocs in Satchel
							let dest_title
							if (this.framework_record.cfo.cfitems[this.CFItem.extensions.sourceItemIdentifier]) {
								dest_title = U.generate_cfassociation_node_uri_title(this.framework_record.cfo.cfitems[this.CFItem.extensions.sourceItemIdentifier], true) + sr(' (:$1:)', this.framework_record.lsdoc_identifier)
							} else {
								// otherwise, construct a "fake" CFItem with the original fullStatement and hrc to use for the title. we don't know the original framework, so don't include it
								let dest_item = new CFItem({fullStatement: this.original_fullStatement, humanCodingScheme: this.original_humanCodingScheme})
								dest_title = U.generate_cfassociation_node_uri_title(dest_item, true)
							}
							let assoc = new CFAssociation({
								associationType: 'isRelatedTo',
								originNodeURI: {
									title: U.generate_cfassociation_node_uri_title(this.CFItem, true) + sr(' (:$1:)', this.framework_record.lsdoc_identifier),
									identifier: this.CFItem.identifier,
									uri: this.CFItem.uri,
								},
								destinationNodeURI: {
									title: dest_title,
									identifier: this.CFItem.extensions.sourceItemIdentifier,
									uri: this.CFItem.extensions.sourceItemURI,
								}
							})

							// fill in other parts of the CFAssociation object, then push to data.CFAssociations
							assoc.complete_data(this.framework_record.json.CFDocument)	// this will add '*NOW*' as the lastChangeDateTime
							data.CFAssociations.push(assoc.to_json())
						}
					}
					this.create_related_to = false

					// Clear the sourceItemIdentifier/URI fields from the (former) copy
					delete this.CFItem.extensions.sourceItemIdentifier
					delete this.CFItem.extensions.sourceItemURI
					// then re-run to_json_for_update, because the whole extensions sub-object may now need to be cleared
					data.CFItems[0] = this.CFItem.to_json_for_update()
				}
			}
			// BELOW REMOVED BY PW on 9/10/2023 -- I don't think we can count on doing this reliably, and we never actually implemented this in the service
			// else if the item is an original with copies in this framework and the fullStatement changed...
			// 	} else if (this.item_copies_in_this_framework && this.fullStatement_is_changed) {
			// 		for (let item of this.framework_record.json.CFItems) {
			// 			let sourceItemIdentifier = item.sourceItemIdentifier ?? item.extensions?.sourceItemIdentifier
			// 			if (sourceItemIdentifier == this.CFItem.identifier) {
			// 				let i2
			// 				// If I2.lastChangeDateTime == I1.lastChangeDateTime, update all fields of I2 to match the new values in I1 (except identifier/URI/sourceIdentifier/URI)
			// 				if (item.lastChangeDateTime == original_lastChangeDateTime) {
			// 					i2 = $.extend(true, {}, json_to_save)
			// 					i2.identifier = item.identifier
			// 					i2.uri = item.uri
			// 					i2.sourceItemIdentifier = item.extensions.sourceItemIdentifier
			// 					i2.sourceItemURI = item.extensions.sourceItemURI

			// 				// else set I2.fullStatement = I1.fullStatement (because values other than fullStatement were presumably changed in I2)
			// 				} else {
			// 					i2 = new CFItem(item)
			// 					i2.generate_date()
			// 					i2 = i2.to_json_for_update()
			// 					i2.fullStatement = this.CFItem.fullStatement
			// 				}

			// 				// add to array of CFItems to update
			// 				data.CFItems.push(i2)
			// 			}
			// 		}

			// 		// note: if the item has copies in other frameworks, those will be saved by the service -- NEVER ACTUALLY IMPLEMENTED
			// 	}
			// }

			data.show_spinner = false	// don't show spinner while saving an individual item
			console.log(data)
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// create or update the CFItem(s) in the framework json
				for (let cfi of data.CFItems) {
					let index = this.framework_record.json.CFItems.findIndex(x=>x.identifier == cfi.identifier)

					// use server date/time for all timestamps
					if (cfi.lastChangeDateTime == '*NOW*') {
						cfi.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}

					// convert '*CLEAR*' properties back to empty values before transferring in to client memory
					for (let key in cfi) if (cfi[key] === '*CLEAR*') delete cfi[key]
					if (cfi.extensions) for (let key in cfi.extensions) if (cfi.extensions[key] === '*CLEAR*') delete cfi.extensions[key]

					// then get a new clean json object
					let clean_json = new CFItem(cfi).to_json()

					if (index == -1) {
						this.$store.commit('set', [this.framework_record.json.CFItems, 'PUSH', clean_json])
					} else {
						this.$store.commit('set', [this.framework_record.json.CFItems, 'SPLICE', index, clean_json])
					}

					// transfer updates to the cfo cfitem
					let cfitem = this.framework_record.cfo.cfitems[cfi.identifier]
					this.update_cfitem_from_json(cfitem, cfi)
				}

				// we may have saved CFAssociations
				for (let assoc of data.CFAssociations) {
					// use server date/time for all timestamps
					if (assoc.lastChangeDateTime == '*NOW*') {
						assoc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					}

					// add to or update in the CFAssociations
					let index = this.framework_record.json.CFAssociations.findIndex(x=>x.identifier == assoc.identifier)
					if (index == -1) {
						this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', assoc])
					} else {
						this.$store.commit('set', [this.framework_record.json.CFAssociations, 'SPLICE', index, assoc])

						// for new non-isChildOf assocs, have to also add to associations_hash for the cfo, and to the tree display. note if we're adding an assoc here, it's an intra-framework assoc
						if (assoc.associationType != 'isChildOf') {
							U.update_associations_hash(this.framework_record.cfo, assoc)
							this.viewer.update_association_display({framework_id: this.lsdoc_identifier, assoc: assoc, actions: ['add', 'display']})
						}
					}
				}

				// if we inserted a new item, remove the new_item_parent_node attribute from original_node
				if (this.is_new_item) {
					this.$store.commit('set', [this.original_node, 'new_item_parent_node', '*DELETE_FROM_STORE*'])
				}

				// reset the changes_pending flag by setting original_CFItem to the just-saved version of this.CFItem. (note that the lastChangeDateTime doesn't matter for this)
				this.original_CFItem = JSON.stringify(this.CFItem.to_json())
				this.original_fullStatement = this.CFItem.fullStatement
				this.original_humanCodingScheme = this.CFItem.humanCodingScheme

				// if applying_suggestions is true, call a callback fn
				if (this.applying_suggestions) {
					this.$emit('item_edit_suggestion_applied', this.CFItem)
				}

				// if the item is type 'Metadata Category',
				if (this.CFItem.CFItemType == 'Metadata Category') {
					// then if this item's fullStatement isn't already an item type, add it to item types -- we do this because definitionally we may create instances of this category
					if (!this.cfo.item_types.includes(this.CFItem.fullStatement)) {
						this.$store.commit('set', [this.cfo.item_types, 'PUSH', this.CFItem.fullStatement])
					}
				}

				// call update_frameworks_with_associations in case association data needs to be updated
				this.viewer.update_frameworks_with_associations()

				// if flag == 'and_done', close the editor now
				if (flag == 'and_done') this.cancel_edit()
			})
		},

		// same fn is in ItemEditor and DocumentEditor
		update_cfitem_from_json(cfitem, cfitem_json) {
			// add/update properties in cfitem_json that weren't in cfitem
			for (let key in cfitem_json) {
				let val = cfitem_json[key]
				if (typeof(val) == 'object') {
					// if key 0 exists, assume it's an array
					if (!empty(val[0])) {
						let arr = []
						for (let index in val) {
							arr.push(val[index])
						}
						val = arr

					} else {
						val = $.extend(true, {}, val)
					}
				}
				this.$store.commit('set', [cfitem, key, val])
			}

			// remove properties from cfitem that *aren't* in cfitem_json
			for (let key in cfitem) {
				// WATCH IT!!! skip properties added by U.create_cfo_cfitem
				if (key == 'tree_nodes' || key == 'stats') continue
				else if (key == 'extensions') {
					for (let ekey in cfitem.extensions) {
						if (!cfitem_json.extensions || typeof(cfitem_json.extensions[ekey]) == 'undefined') {
							this.$store.commit('set', [cfitem.extensions, ekey, '*DELETE_FROM_STORE*'])
						}
					}
				} else if (typeof(cfitem_json[key]) == 'undefined') {
					this.$store.commit('set', [cfitem, key, '*DELETE_FROM_STORE*'])
				}
			}
		},

		delete_item() {
			this.edit_actions_menu_showing = false
			// if this is a new item, delete it without saving or confirming
			if (this.is_new_item) {
				U.delete_node_from_cfo(this.cfo, this.original_node)
				this.$emit('dialog_cancel')
				return
			}

			let confirm_text = 'Are you sure you want to delete this item?'
			if (this.original_node.children.length > 0) {
				confirm_text = sr('Are you sure you want to delete this item and its $1 $2 (and possible grandchildren)?', this.original_node.children.length, U.ps('child', this.original_node.children.length, 'children'))
			}

			// if the parent has multiple tree_nodes, the item will be deleted from each of those places in the tree -- tell the user this
			if (this.original_node.parent_node.cfitem.tree_nodes.length > 1) {
				let other_removals = this.original_node.parent_node.cfitem.tree_nodes.length - 1
				let places = U.ps('another place', other_removals, other_removals + ' other places')
				let places2 = U.ps('place', other_removals)
				confirm_text += sr('<div class="mt-2"><b>Note:</b> the item will also be removed from $1 in the framework tree (the other $2 where the item’s parent, “$3”, exists in the tree).</div>', places, places2, this.original_node.parent_node.cfitem.fullStatement)
			}

			// if the node’s identifier also has other associations, the item will still exist on those other places in the tree -- tell the user this
			let assocs = this.framework_record.json.CFAssociations.filter(o => o.associationType == 'isChildOf' && o.originNodeURI.identifier == this.CFItem.identifier)
			if (assocs.length > 1) {
				confirm_text += '<div class="mt-2"><b>Note:</b> the item will still exist in at least one other place in the framework tree.</div>'
			}

			if (this.original_node.parent_node.cfitem.tree_nodes.length > 1 || assocs.length > 1) {
				// this.$store.commit('set', [this.framework_record, 'search_terms', this.CFItem.identifier])
				confirm_text += sr('<div class="mt-2">(To highlight and review all places where the item currently exists in the tree, click CANCEL, then search for this item’s identifier ($1).</div>', this.CFItem.identifier)
			}

			this.$confirm({
			    title: 'Delete Item',
			    text: confirm_text,
			    acceptText: 'Delete',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				this.$store.dispatch('delete_framework_items', {framework_record: this.framework_record, nodes_to_delete: [this.original_node]}).then(()=>{
					// clear the active node and starting_lsitem_identifier
					this.viewer.make_node_active('', true)

					this.$emit('dialog_cancel')
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_item_not_children() {
			if (this.original_node.children.length == 0) {	// shouldn't happen
				this.$alert('This item does not have any children')
				return
			}

			let confirm_text = sr('This action will delete this parent item, but move the <nobr>parent’s $1</nobr> (and possible grandchildren) “up” to be children of this item’s parent.', U.ps('child', this.original_node.children.length, this.original_node.children.length + ' children'))
			this.$confirm({
				title: 'Delete Parent but Preserve Children',
				text: confirm_text,
				acceptText: 'Proceed',
				acceptColor: 'red',
				dialogMaxWidth: 500
			}).then(y => {
				// first move children up one level

				// get current isChildOf assoc between this item and its parent
				let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, this.item_identifier, this.original_node.parent_node.cfitem.identifier)
				if (index == -1) return	// shouldn't happen
				let current_item_assoc = this.framework_record.json.CFAssociations[index]
				let destinationNodeURI = current_item_assoc.destinationNodeURI

				// starting sequence number should be at the end of this item's parent's children
				// let sequenceNumber = this.original_node.parent_node.children.length + 1
				let sequenceNumber = U.get_next_sequenceNumber(this.original_node.parent_node, this.framework_record)

				let data = {
					lsdoc_identifier: this.lsdoc_identifier,
					CFAssociations: [],
				}

				// following the logic from MoveEditor.item_move_drag_complete, move children to the end of this item's parent
				for (let i = 0; i < this.original_node.children.length; ++i) {
					let child_node = this.original_node.children[i]
					// find the association for the item we're moving
					let index = U.find_cfassociation_index(this.framework_record.json.CFAssociations, child_node.cfitem.identifier, this.item_identifier)
					if (index == -1) return	// this shouldn't happen

					// change the association so it's pointing to the new parent, then add to data
					let target_association = this.framework_record.json.CFAssociations[index]
					this.$store.commit('set', [target_association, 'destinationNodeURI', $.extend({}, destinationNodeURI)])
					this.$store.commit('set', [target_association, 'sequenceNumber', sequenceNumber])
					this.$store.commit('set', [target_association, 'lastChangeDateTime', '*NOW*'])	// set lastChangeDateTime to *NOW* so server will update to the correct timestamp
					data.CFAssociations.push(new CFAssociation(target_association).to_json())

					// increment sequenceNumber (which is only used for the CFAssociations)
					++sequenceNumber

					// remove the original_node from its previous parent's [children], wherever the parent might exist; don't delete the cfitem though (that's what the 'true' as the last parameter does)
					U.delete_node_from_cfo(this.cfo, child_node, true)

					// add the node to the end of the new parent's children array
					U.add_child_to_cfo(child_node.cfitem, this.original_node.parent_node, this.$store, this.cfo, null, child_node.children)

					// decrement i, because we just took this item out of children
					--i
				}

				// save these changes
				data.show_spinner = false	// don't the spinner for moves
				this.$store.dispatch('save_framework_data', data).then(()=>{
					// when done, update the CFAssociations' change dates
					for (let cfa of data.CFAssociations) {
						if (cfa.lastChangeDateTime == '*NOW*') {
							let json_CFA = this.framework_record.json.CFAssociations.find(x=>x.identifier == cfa.identifier)
							this.$store.commit('set', [json_CFA, 'lastChangeDateTime', this.$store.state.framework_lastChangeDateTime])
						}
					}

					// then delete the original_node
					this.$store.dispatch('delete_framework_items', {framework_record: this.framework_record, nodes_to_delete: [this.original_node]}).then(()=>{
						// clear the active node and starting_lsitem_identifier
						this.viewer.make_node_active('', true)

						this.$emit('dialog_cancel')
					})
				})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_children() {
			if (this.original_node.children.length == 0) {
				this.$alert('This item does not have any children to delete!')
				return
			}

			let confirm_text = sr('Are you sure you want to delete this item’s $1 (and possible grandchildren)? (This item itself will not be deleted.)', U.ps('child', this.original_node.children.length, this.original_node.children.length + ' children'))
			this.$confirm({
				title: 'Delete Children',
				text: confirm_text,
				acceptText: 'Delete',
				acceptColor: 'red',
				dialogMaxWidth: 500
			}).then(y => {
				let nodes_to_delete = []
				for (let child of this.original_node.children) {
					nodes_to_delete.push(child)
				}

				this.$store.dispatch('delete_framework_items', {framework_record: this.framework_record, nodes_to_delete: nodes_to_delete}).then(()=>{})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		add_child(confirmed) {
			this.edit_actions_menu_showing = false
			if (this.changes_pending && confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'You’ve made changes to this item. If you create a new child item now, those changes will be lost. Click “Discard Changes” to proceed. (You can also click “Cancel” here, save your changes, then click again to add a child item.)',
				    acceptText: 'Discard Changes and Add Child Item',
					acceptColor: 'red',
					dialogMaxWidth: 600
				}).then(y => {
					this.add_child(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// assume that a number of things are the same for the child as the parent
			let child_cfitem_data = {
				language: this.CFItem.language,
				educationLevel: this.CFItem.educationLevel,
				alternativeLabel: this.CFItem.alternativeLabel,
				CFItemType: this.CFItem.CFItemType,
				CFItemTypeURI: this.CFItem.CFItemTypeURI,
				conceptKeywords: this.CFItem.conceptKeywords,
				conceptKeywordsURI: this.CFItem.conceptKeywordsURI,
				licenseURI: this.CFItem.licenseURI,
			}

			// if the parent has children, use the item type of the last child
			if (this.original_node.children.length > 0) {
				let last_child_cfitem = this.original_node.children[this.original_node.children.length-1].cfitem
				child_cfitem_data.CFItemType = U.item_type_string(last_child_cfitem)

				// also try to get an hcs here from that last child
				child_cfitem_data.humanCodingScheme = this.guess_at_hcs(last_child_cfitem.humanCodingScheme)

			// else if the parent has a CFItemType...
			} else if (!empty(this.CFItem.CFItemType)) {
				// if the parent is a Metadata Category, use its fullStatement as this item's type
				if (this.CFItem.CFItemType == 'Metadata Category') {
					child_cfitem_data.CFItemType = this.CFItem.fullStatement

				} else {
					// else if there's a "lower level" in item_types, use that lower level
					let index = this.item_types.findIndex(x=>x.value == this.CFItem.CFItemType)
					if (index > 0 && index < this.item_types.length-1) {
						child_cfitem_data.CFItemType = this.item_types[index+1].value
					}
				}
			}

			// if we didn't get an hcs above, try to guess at a reasonable value for the hcs of the parent
			if (empty(child_cfitem_data.humanCodingScheme) && !empty(this.CFItem.humanCodingScheme)) {
				child_cfitem_data.humanCodingScheme = this.CFItem.humanCodingScheme + '.x'
			}

			// use the CFItem constructor, with complete_data and to_json, to get a new identifier, uri, etc.
			child_cfitem_data = new CFItem(child_cfitem_data)
			child_cfitem_data.complete_data(this.CFDocument)
			child_cfitem_data = child_cfitem_data.to_json()

			// get the sequenceNumber to use for the new item, and put this in the node.sequence parameter to use if/when the new item is saved
			let sequenceNumber = U.get_next_sequenceNumber(this.original_node, this.framework_record)

			// add a new item to the cfo (we'll take it back out if needed); put the sequenceNumber we will save the item to in node.sequence, for use in ItemEditor.save_changes
			// note: since sequenceNumber is guaranteed to be higher than the last index of the parent's children array, the item will always get spliced to the end of children
			let child_node = U.add_child_to_cfo(child_cfitem_data, this.original_node, this.$store, this.cfo, sequenceNumber)

			// add the original_node as the new_item_parent_node
			this.$store.commit('set', [child_node, 'new_item_parent_node', this.original_node])

			// make sure the parent is open in the tree
			this.$nextTick(x=>this.$emit('make_node_open', this.original_node.tree_key))

			// open the child for editing
			this.$emit('edit_new_child', child_node)
		},

		guess_at_hcs(hcs) {
			// try to guess at a reasonable value for the hcs of a copy based on the hcs of its sibling
			if (!empty(hcs) && hcs.search(/(.*?)([0-9]+|[a-z]+|[A-Z]+)$/) > -1) {
				let r1 = RegExp.$1
				let r2 = RegExp.$2
				if (r2.length == 1) {
					if (r2 == '9') r2 = '10'
					if (r2 == 'z') r2 = 'za'
					if (r2 == 'Z') r2 = 'ZA'
					r2 = String.fromCharCode(r2.charCodeAt(0) + 1)
					return sr('$1$2', r1, r2)
				} else {
					return sr('$1[$2]', r1, r2)
				}
			} else return ''
		},

		add_sibling(confirmed) {
			this.edit_actions_menu_showing = false
			if (this.changes_pending && confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'You’ve made changes to this item. If you create a new sibling item now, those changes will be lost. Click “Discard Changes” to proceed. (You can also click “Cancel” here, save your changes, then click again to add a sibling item.)',
				    acceptText: 'Discard Changes and Add Sibling Item',
					acceptColor: 'red',
					dialogMaxWidth: 600
				}).then(y => {
					this.add_child(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// assume that a number of things are the same for the child as the parent
			let child_cfitem_data = {
				language: this.CFItem.language,
				educationLevel: this.CFItem.educationLevel,
				alternativeLabel: this.CFItem.alternativeLabel,
				CFItemType: this.CFItem.CFItemType,
				CFItemTypeURI: this.CFItem.CFItemTypeURI,
				conceptKeywords: this.CFItem.conceptKeywords,
				conceptKeywordsURI: this.CFItem.conceptKeywordsURI,
				licenseURI: this.CFItem.licenseURI,
				humanCodingScheme: this.guess_at_hcs(this.CFItem.humanCodingScheme)
			}

			// use the CFItem constructor, with complete_data and to_json, to get a new identifier, uri, etc.
			child_cfitem_data = new CFItem(child_cfitem_data)
			child_cfitem_data.complete_data(this.CFDocument)
			child_cfitem_data = child_cfitem_data.to_json()

			// get the sequenceNumber to use for the new item, and put this in the node.sequence parameter to use if/when the new item is saved
			let sequenceNumber = U.get_next_sequenceNumber(this.original_node.parent_node, this.framework_record)

			// add a new item to the cfo (we'll take it back out if needed); put the sequenceNumber we will save the item to in node.sequence, for use in ItemEditor.save_changes
			// note: since sequenceNumber is guaranteed to be higher than the last index of the parent's children array, the item will always get spliced to the end of children
			let child_node = U.add_child_to_cfo(child_cfitem_data, this.original_node.parent_node, this.$store, this.cfo, sequenceNumber)

			// add the original_node's parent_node as the new_item_parent_node
			this.$store.commit('set', [child_node, 'new_item_parent_node', this.original_node.parent_node])

			// open the sibling for editing
			this.$emit('edit_new_child', child_node)

			// make sure the parent is open in the tree
			this.$emit('make_node_open', this.original_node.parent_node.tree_key)
		},

		show_copy_interface() {
			this.edit_actions_menu_showing = false
			this.copy_interface_showing = true
		},

		show_text_import_interface() {
			this.edit_actions_menu_showing = false
			this.text_import_interface_showing = true
		},

		show_csv_import_interface() {
			this.edit_actions_menu_showing = false
			this.csv_import_interface_showing = true
		},

		preview_html(field) {
			// get basic text
			let s = (field == 'supplementalNotes') ? this.CFItem.extensions.supplementalNotes : this.CFItem[field]

			// use markup + latex for fullStatement and notes, and supplementalNotes if we're using markup; otherwise just use latex
			if (field == 'fullStatement' || field == 'notes' || (field == 'supplementalNotes' && !this.use_html_for_supplementalNotes)) s = U.marked_latex(s)
			else s = U.render_latex(s)

			// for notes, make sure all links open in a new window
			if (field == 'notes') s = s.replace(/<a /g, '<a target="blank" ')

			// add classes to mimic how it will appear in the tile
			let cl = ''
			if (field == 'fullStatement') cl = 'k-case-tree-item-statement k-case-tree-markdown'
			else if (field == 'notes') cl = 'k-case-tree-item-details-notes k-case-tree-markdown'
			s = sr('<div class="$1">$2</div>', cl, s)

			// add fr-view if we're in suggestions_only mode (in which case the user can use rich text) for supplementalNotes (which always uses rich text)
			if (this.suggestions_only || field == 'supplementalNotes') {
				s = sr('<div class="fr-view">$1</div>', s)
			}

			return s
		},

		show_preview(field) {
			let s = (field == 'supplementalNotes') ? this.CFItem.extensions.supplementalNotes : this.CFItem[field]
			if (s.search(/[*_$`]/) > -1) return true
			// roman-numeral-type list
			if (s.search(/\n([ivx]+)\. (.*)/) > -1) return true
			// alpha-type list
			if (s.search(/\n([a-z])\. (.*)/i) > -1) return true
			return false
		},

		latex_help() {
			let msg = sr('Complex math and scientific notation can be added to Full Statements, Notes, and $1 using LaTeX format. Add dollar signs on either side of the LaTeX, and don’t add any extraneous spaces between the dollar signs. For example, this:<br><br>$\sqrt[3]{5}$<br><br>will render as this:<br><br>$2<br><br> Help with LaTeX formatting is a Google search away; for a quick reference to common math formatting, see <a href="/latex-help.pdf" target="_blank"><b>this PDF</b></a>.', this.framework_record.ss_framework_data.exemplar_label, U.render_latex(' $\\sqrt[3]{5}$ '))
			this.$alert(msg)
		},

		find_latex_expressions(s) {
			// skip expensive regexp if no latex
			if (!s.includes('$')) return []

			// by convention, latex is coded like this:
			// For example, we define $5^{(1/3)}$ to be the cube root of 5 because we want $[5^{(1/3)}]^3$ = $5^{[(1/3) x 3]}$ to hold, so $[5^{(1/3)}]^3$ must equal 5.
			let results = []
			s = '[' + s + ']'
			let re = /([\s({\[>])\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<])/g
			let last_lastIndex = 0
			for (let i = 0; i < 100; ++i) {		// to make sure we don't get into an infinite loop
				re.lastIndex = last_lastIndex
				let arr = re.exec(s)

				// once we don't find anything, break
				if (!arr) break

				// if we found something, record it; note that we added a '[' to the start of s for the regex, so we have to subtract 1 from start and end; but then we want to "skip over" the opening $, which requires adding 2; hence the “+ 1” fpr start and end
				// console.log(re.lastIndex, arr)
				results.push({latex: arr[2], start: arr.index + 1, end: arr.index + 1 + arr[2].length})
				last_lastIndex = arr.index + arr[0].length
			}
			return results
		},

		check_for_latex_position($evt, field, evt_type) {
			// get cursor position
			let pos = $evt.target.selectionStart

			// look for latex expressions in the field
			let arr = this.find_latex_expressions(this.CFItem[field])

			// if this position is within an existing expression, set cursor_latex_expression[field] to this object
			let o = arr.find(x=>pos >= x.start-1 && pos <= x.end+1)

			// otherwise if cursor is to the right of a dollar sign, prepare for the user to enter a new expression, using a trigger that says to show the btn to edit latex even if the framework isn't marked as being one that uses latex
			if (!o && this.CFItem[field][pos-1] == '$') o = {latex:'XXDOLLARXX', start: pos, end: pos}

			// otherwise prepare for the user to enter a new expression if the framework is being marked as being one that uses latex
			if (!o) o = {latex:'', start: pos, end: pos}

			this.cursor_latex_expression[field] = o
			// console.log('check_for_latex_position', this.CFItem[field][pos], o.latex)
		},

		edit_latex(field) {
			vapp.show_math_live_editor(this.cursor_latex_expression[field].latex, new_latex => {
				let new_value
				let o = this.cursor_latex_expression[field]

				// if we're inserting a new expression, do so
				if (empty(o.latex) || o.latex == 'XXDOLLARXX') {
					// if o.latex is 'XXDOLLARXX', strip out the $ that was there
					let slice_to = o.start
					if (o.latex == 'XXDOLLARXX') slice_to -= 1

					new_value = this.CFItem[field].slice(0, slice_to)
					if (new_value[new_value.length-1] != ' ') new_value += ' '
					new_value += `$${new_latex}$`
					new_value += this.CFItem[field].slice(o.end)

				} else {
					// else insert the new latex in the old expression
					new_value = 
						this.CFItem[field].slice(0, o.start)
						+ new_latex
						+ this.CFItem[field].slice(o.end)
				}

				// replace
				o.latex = new_latex

				this.CFItem[field] = new_value
			})
		},
	}
}
</script>

<style lang="scss">
// note that the k-editor-wrapper classes are used for all editor interfaces
.k-editor-wrapper-outer {
	position:fixed;
	z-index:100;
	// left:530px;
	left: calc(50vw + 12px);
	// left: calc(50vw - 565px + 520px);
	top:0px;
	height:100vh;
}

.k-editor-wrapper {
	position:absolute;
	top:12px;
	width:600px;
	max-width:calc(50vw - 32px);
	border-radius:8px;
	border:1px solid #ddd;
	// elevation-2
	box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
	background-color:#fff;
}

.k-editor-wrapper-wide {
	width:calc(50vw - 32px);
}

.k-editor-title {
	font-weight:bold;
	font-size:18px;
	padding:8px 12px;
	border-bottom:1px solid #ccc;
	white-space:nowrap;
	overflow:hidden;

	.fas {
		font-size:20px!important;
		margin-top:-3px;
	}

	.k-editor-info-icon {
		font-size:24px!important;
		color:$v-light-blue !important;
	}
}

.k-editor-close-btn {
	position:absolute;
	right:-8px;
	top:-8px;
	background-color:#fff;
	z-index:1000;
}

// NOTE: despite this class's name and the overflow:auto rule, this div is *not* usually scrollable in most situations
.k-case-item-editor-scrollable {
	padding:8px 12px;
	max-height:calc(100vh - 272px);
	overflow:auto;
}

.k-case-item-editor-scrollable-taller {
	max-height:calc(100vh - 112px);
}

.k-case-item-editor-scrollable-tallest {
	max-height:calc(100vh - 68px);
}

.k-case-item-editor-buttons {
	display:flex;
	align-items: center;
	border-top:1px solid #ccc;
	padding:8px;
}

.k-case-ie-line {
	display:flex;
	align-items:center;
	margin-bottom:12px;

	input, .v-select__selection {
		font-size:14px;
	}

	textarea {
		line-height:22px;
		padding-top:0px;
		padding-bottom:8px!important;
		margin-top:8px!important;
		font-size:14px;
		line-height:18px!important;
	}
}

.k-case-ie-line-label {
	font-weight:bold;
	color:#444;
	margin-bottom:4px;
	margin-left:4px;
	font-size:13px;
	line-height:16px;
	// width:120px;
	// text-align:right;
}

.k-case-ie-line-body {
	flex: 1 0 auto;
	display:flex;
	align-items:center;
	.k-case-ie-line-label {
		width:auto;
		margin-left:24px;
	}
	.v-label {
		color:#999!important;
	}
}

.k-editor-markup-preview {
	display:block; 
	border-radius:4px;
	background-color:#f0f0f0;
	font-size:14px;
	line-height:18px;
	padding:8px;
	margin-top:8px;
}
</style>
