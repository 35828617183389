<script>
import CFRubricEditorFormRubricViewer from './CFRubricEditorFormRubricViewer.vue';

export default {
    name: "CFRubricGrid",
    components: {CFRubricEditorFormRubricViewer},
    props: {
        criteria: Array,
        uri: String,
        rubric_identifier: String,
        framework_record: Object
    },
    model: {
        prop: 'criteria',
        event: 'update:criteria'
    },
    data() {
        return {
            title: "",
            description: "",
            notes: "",
            rows: 0,
            columns: 0,
            errors: {},
        };
    },
    watch: {
        criteria: {
            handler: function (newValue) {
                this.$emit('update:criteria', newValue)
            },
            deep: true
        }
    }
}
</script>

<template>
    <div style=" margin-top: 16px;">
        <CFRubricEditorFormRubricViewer 
            v-model="criteria" 
            :uri="uri" 
            :rubric_identifier="rubric_identifier" 
            :framework_record="framework_record"
        />
    </div>
</template>
